import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';
@Component({
  selector: 'app-group-baraka',
  templateUrl: './group-baraka.component.html',
  styleUrls: ['./group-baraka.component.scss']
})
export class GroupBarakaComponent implements OnInit {

  band: Band;

  constructor() { }

  ngOnInit(): void {
    this.band = {
      name: "Baraka",
      genre: "Fritcore",
      biography: "En novembre 2005, sur le sol gras et huileux de <b>Braine l'Alleud</b>, Ronny Deff et sa bande de potes liés par la bière et les belles mélodies se mirent en tête de faire de la musique.<br/><b>Baraka keskeçé ?</b> Une poignée de riffs Hardcore, 2 ou 3 louches de Death et Black Metal et une grosse motte de beurre. Saupoudrez de paroles humoristiques basées sur les fiertés de notre pays, 5 kg de poils et de mulet, de la bière, de la sueur, du gras et un peu de merde. Vous enfournez le tout sur scène à température bouillante et vous obtiendrez une soirée d'enfer !",
      image: "assets/images/bands/baraka/banner.png",
      logo: "assets/images/bands/baraka/logo.png",
      title: "Baraka",
      backgroundImage: "/assets/images/backgrounds/marbre.jpg",
      teamImage: "/assets/images/bands/baraka/smallbands-baraka-band.jpg",
      facebookURL: "http://facebook.com/barakaBelgium/",
      instagramURL: "https://www.instagram.com/barakabelgium/",
      reverbnation: "https://www.reverbnation.com/barakabelgium",
    }
  }

}
