import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-group-fleddy-melculy',
  templateUrl: './group-fleddy-melculy.component.html',
  styleUrls: ['./group-fleddy-melculy.component.scss']
})
export class GroupFleddyMelculyComponent implements OnInit {

  band: Band;

  constructor(private gallery: Gallery) { }

  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/bands/fleddymelculy/gallery_zoom_1.jpg'},
            {path: '/assets/images/bands/fleddymelculy/gallery_zoom_2.jpg'}
        ],
        index
    };
    this.gallery.load(prop);
  }

  ngOnInit(): void {
    this.band = {
      name: "Fleddy Melculy",
      genre: "Metal",
      biography: "Fleddy Melculy est un <b>groupe de metal belge</b> qui s'est fait connaître en 2016 avec leur premier single <i>'T-shirt van Metallica'</i>. Après avoir signé avec <b>Sony Music</b>, leur premier album <i>'HELGIË'</i> s'est vendu à près de 10 000 exemplaires en Belgique, confirmant leur place de groupe de metal belge le plus vendu. Depuis, le groupe a enchaîné les concerts, les festivals et ont même organisé leur propre festival, <i>FLEDDYPALOOZA</i>, où ils partagent la scène avec d'autres groupes de rock émergents et renommés.<br/>Fleddy Melculy est devenu une valeur sûre de la scène musicale belge, avec une réputation de groupe de metal qui sait prendre possession de la scène et ravir son public.",
      image: "assets/images/bands/fleddymelculy/banner.jpg",
      logo: "assets/images/bands/fleddymelculy/logo.png",
      title: "Fleddy Melculy",
      teamImage: "/assets/images/bands/fleddymelculy/small-bands-fleddymelculy-band.png",
      facebookURL: "https://www.facebook.com/fleddymelculy",
      instagramURL: "https://www.instagram.com/fleddymelculy/",
      youtubeURL: "https://www.youtube.com/@FleddyMelculyVEVO",
      spotifyURL: "https://open.spotify.com/artist/4yMBnQTFuTAQkEoU1yKH5x?si=UmUUbMfJTHyzEeLIfpdDAQ",
      websiteURL: "https://www.fleddymelculy.com/",
      twitterURL: "https://twitter.com/fleddymelculy",
      tiktokURL: "https://www.tiktok.com/@fleddymelculy",
    }
  }

}
