import { Component, Input, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';

@Component({
  selector: 'app-group-bottom',
  templateUrl: './group-bottom.component.html',
  styleUrls: ['./group-bottom.component.scss']
})
export class GroupBottomComponent implements OnInit {

  @Input() band: Band;

  constructor() { }

  ngOnInit(): void {
  }

}
