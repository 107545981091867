
<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <img src="assets/images/bands/shaarghot/title.svg" alt="Etienne Bianchi">                                           
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/shaarghot/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"C'est beau mais c'est gras..."</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/shaarghot/avatar.png" alt="Etienne Bianchi">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage du chanteur de Shaârghot</p>
                              <p class="band_testimony__content__author--name--name">Etienne Bianchi</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p>Alors ce qu’on ne voit pas dans la BD, <b>c’est ce qui se passe avant.</b> Faut savoir qu’on se peinturlure pendant <b>2 heures</b> et comme on doit être entièrement en noir, on y va carrément au gros pinceau!</p>
                <p>Donc se barbouiller avant puis se débarbouiller après, ça prend un temps monstre… <b>mais bon, ça en vaut la peine non?</b></p>
                <div class="embedresize">
                    <div>
                        <iframe src="https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2Fshaarghot%2Fvideos%2F1186350475509469%2F&show_text=false&width=560&t=0" width="500" height="375" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                    </div>
                </div>
                <p>Après le concert, on aime bien se balader dans le public pour échanger, prendre des photos et trinquer avec ceux qui sont venus nous voir. <b>Eux aussi ne sont pas à l'abri des traces noires</b>, comme en témoigne la photo juste en dessous. Du coup, en sortant du concert, c’est plutôt facile de repérer qui était présent au concert de Shaârghot !</p>
              </div>
              <div class="gallery">
                  <img src="/assets/images/bands/shaarghot/gallery_thumbnail_1.jpg" (click)="showGallery(0)">
              </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Hellfest From Home 2021</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/p3l8A_i_pK0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/0wxpqCSmhtwnRXoWPoHAcj?utm_source=generator&theme=0" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/bands/shaarghot/chaines1.png" alt="Kilt" class="top-right">
        <img src="assets/images/bands/shaarghot/chaines2.png" alt="Kilt" class="bottom-left">
    </div>
</div>