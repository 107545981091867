
<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <img src="assets/images/bands/versatile/title.svg" alt="Versatile">                                                    
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/versatile/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"Creux-du-Van"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/versatile/avatar.png" alt="Cinis">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage de la guitariste de Versatile</p>
                              <p class="band_testimony__content__author--name--name">Cinis</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p>On a tourné notre clip au mois d'avril. <b>Au programme :</b> tournage au <b>sommet de la falaise des Creux-du-Van</b> et au <b>cœur des grottes suisses</b>. À quelques jours du tournage, la météo semblait clémente, tout s'alignait pour que le tournage soit une réussite ! <b>Départ prévu à 3h30 du matin pour être les premiers sur le site</b>, filmer les images au lever de soleil et éviter les promeneurs. Comme vous l'avez lu dans la BD « éviter les promeneurs », c’était plutôt raté…</p>
                <img src="assets/images/bands/versatile/trou.png" alt="photo">
                <p>Avant ça, on devait arriver en haut de la falaise, donc autant dire que le lever du soleil, c’était raté aussi. Mais le pire, c’était qu'en fait là-haut… <b>il neigeait</b>. C’est là que j’ai regretté mon choix vestimentaire pour le groupe… On va dire que ma tenue de scène n’a pas été vraiment pensée pour faire de la luge en montagne. <b>En même temps quand tu fais partie d’un groupe de Black Metal tu ne vas pas tourner un clip en moufles et bonnet à pompon!</b></p>
                <p>Alors, les autres figurantes et moi avons pris notre courage à deux mains <i>(glacées)</i> pour tourner cette fameuse scène.</p>
                <p>Faut dire que ces images donnent vraiment super bien grâce à la neige, mais rien que d'y repenser, <b>j'en ai des frissons.</b></p>
                <img src="assets/images/bands/versatile/montagne.png" alt="photo">
                <p><b>Découvrez le clip "<a href="https://www.youtube.com/watch?v=gxDzaqOMVQY&ab_channel=Versatile" target="_blank">Un Hiver de Cendre</a>"</b></p>
              </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1 id="clip">Un Hiver de Cendre</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/gxDzaqOMVQY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc bloc-versatile">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/2Kdb8NYcd8uFcQCy1cYDjg?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/bands/versatile/deco1.png" alt="Photo" class="top-right">
        <img src="assets/images/bands/versatile/album2.png" alt="Album" class="bottom-left">
    </div>
</div>