import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';
import { Gallery } from 'angular-gallery';


@Component({
  selector: 'app-group-skal',
  templateUrl: './group-skal.component.html',
  styleUrls: ['./group-skal.component.scss']
})
export class GroupSkalComponent implements OnInit {

  band: Band;
  
  constructor(private gallery: Gallery) { }

  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/bands/skal/gallery_zoom_1.jpg'},
            {path: '/assets/images/bands/skal/gallery_zoom_2.jpg'}
        ],
        index
    };
    this.gallery.load(prop);
  }

  ngOnInit(): void {
    this.band = {
      name: "Skal",
      genre: "Folk Metal Instrumental",
      biography: "<b>Skål</b> est un groupe de <b>Folk Metal Instrumental déjanté</b> qui a été fondé en 2012 par quatre vikings assoiffés de houblon! Inspiré par des groupes comme Ensiferum, Alestorm ou encore Equilibrium, <b>Skål allie à la fois des mélodies festives, rapides et puissantes.</b><br/>Il ne reste plus qu’à lever sa corne, crier Skål et picoler!",
      image: "assets/images/bands/skal/banner.png",
      logo: "assets/images/bands/skal/logo.png",
      title: "Skål",
      backgroundImage: "/assets/images/backgrounds/wood3.jpg",
      teamImage: "/assets/images/bands/skal/small-bands-skal-band.png",
      spotifyURL: "https://open.spotify.com/artist/2gtiNhTHlWXLtajQlHstO6?si=5-82RFGrQbSPfSTfeofrVA",
      facebookURL: "https://www.facebook.com/skal.belgium/",
      twitterURL: "https://twitter.com/skalmetal",
      youtubeURL: "https://www.youtube.com/channel/UCnl9zq0eCR2sMJrtg_n-Q3Q",
      instagramURL: "https://www.instagram.com/skalmetal/",
      websiteURL: "http://skalmetal.be/",
    }
  }
}
