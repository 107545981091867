
<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <img src="assets/images/bands/lajungle/title.svg" alt="La Jungle">                                
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/lajungle/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"Un bon vinyle"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/lajungle/avatar.png" alt="Rémy et Mathieu">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage des membres de La Jungle</p>
                              <p class="band_testimony__content__author--name--name">Rémy et Mathieu</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p>Ah, on a aussi eu le cas <b>du gars bien arrosé qui nous a acheté un vinyle dédicacé</b> à sa demande en jurant qu'il allait le récupérer en partant. Sauf qu'au final, il s'est barré sans, sûrement trop perché pour s'en souvenir.</p>
                <p>On a décidé de jouer les gentils et de lui expédier le vinyle par la poste. Le truc a atterri chez Mondial Relay et a pris ses aises là-bas. Le gars, lui, n'a jamais pointé le bout de son nez pour le récupérer. C'est donc retourné direct chez nous. Bien sûr, on a dû payer les frais d'envoi, et <b>quelques semaines plus tard, voilà qu'il rapplique en se plaignant qu'il n'a jamais reçu son vinyle !</b></p>
              </div>
              <img src="assets/images/bands/lajungle/footer.png" alt="Vinyles">
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Du Sang Du Singe</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/egcnM05-Oi0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc bloc-versatile">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/2M1oEnlS4vNxIW3grVQJ1h?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
       <img src="assets/images/bands/lajungle/deco1.png" alt="Album" class="top-right">
       <img src="assets/images/bands/lajungle/deco2.png" alt="Album" class="bottom-left">
    </div>
</div>