
<div class="wrapper">
    <div class="wrapper__content">
       <div class="content-bloc">
          <div class="content-bloc__header">
            <svg width="216" height="54" viewBox="0 0 216 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                <path d="M23.5205 52.056L19.6364 52.488C18.5574 53.352 15.968 54 11.8681 54C7.33666 54 4.0999 52.92 2.37363 50.544C0.647353 48.384 0 45.144 0 40.392L0.215784 13.608C0.215784 8.208 1.07892 4.536 2.80519 2.592C4.31568 0.864 7.33666 0 11.2208 0C16.1838 0 19.4206 1.296 21.1469 3.672C22.4416 5.4 23.0889 8.424 23.0889 12.528C23.3047 16.416 23.3047 19.224 23.3047 21.168H14.4575C14.4575 18.576 14.2418 15.984 14.2418 13.392C14.026 10.152 13.1628 8.208 11.8681 8.208C10.1419 8.208 9.49451 9.936 9.49451 13.392V41.04C9.49451 41.688 9.71029 42.552 10.1419 43.632C10.5734 44.712 11.2208 45.36 12.0839 45.36C13.8102 45.36 14.6733 42.984 14.6733 38.232V34.776C14.6733 34.128 14.4575 33.912 14.2418 33.912H12.5155L12.9471 25.92C15.3207 25.92 18.5574 25.92 22.6573 25.92L23.7363 27C23.5205 26.784 23.5205 34.776 23.5205 52.056Z" fill="white"/>
                <path d="M53.0831 54.0001H43.8043L43.157 49.6801C42.7254 47.5201 42.2939 45.7921 42.0781 44.0641C42.0781 43.8481 41.8623 43.6321 41.4307 43.6321H38.8413C38.6255 43.6321 38.4097 43.8481 38.194 44.0641C37.7624 46.6561 37.3308 49.6801 36.6835 53.1361L27.189 52.9201C28.6995 44.0641 30.2099 34.9921 31.7204 25.9201L36.8993 0.000134778C39.2729 -0.215865 41.6465 -0.215865 44.0201 0.000134778L48.9832 26.1361C50.2779 35.4241 51.7884 44.7121 53.0831 54.0001ZM41.6465 35.4241C40.9992 30.4561 40.5676 27.8641 40.3518 27.8641C40.136 27.8641 39.9202 28.9441 39.4887 31.1041C39.2729 32.6161 39.0571 33.9121 39.0571 35.4241C39.0571 35.6401 39.2729 35.6401 39.7044 35.6401C40.136 35.6401 40.5676 35.6401 40.9992 35.6401C41.4307 35.6401 41.6465 35.6401 41.6465 35.4241Z" fill="white"/>
                <path d="M77.2508 54.0001H76.6034C68.4036 53.7841 61.9301 53.7841 57.1829 53.7841C56.5355 53.7841 56.3197 53.3521 56.1039 52.2721C56.1039 51.6241 56.1039 50.7601 55.8881 49.6801C55.8881 48.6001 55.6724 47.7361 55.6724 47.0881C55.6724 46.4401 55.6724 46.0081 56.1039 45.3601C56.5355 44.2801 56.7513 43.6321 56.7513 43.6321L65.1669 12.0961C65.3827 11.6641 65.3827 11.0161 65.1669 10.5841C64.9511 10.1521 64.7353 9.93613 64.3037 9.93613C61.7143 10.1521 59.3407 10.1521 57.3986 9.93613L57.1829 0.648129C58.6933 0.648129 61.7143 0.648129 66.4616 0.648129C71.2088 0.648129 74.2298 0.432129 75.5245 0.432129C76.6034 0.432129 77.2508 0.864129 77.4666 1.94413C77.6824 3.67213 77.4666 4.96813 77.2508 6.48013L76.3877 9.93613L67.7563 42.7681C67.5405 44.0641 67.5405 44.7121 68.1879 44.7121C69.2668 44.9281 72.072 44.7121 76.3877 44.4961L77.2508 54.0001Z" fill="white"/>
                <path d="M89.7661 52.056L80.7031 51.84C80.7031 36.936 80.9189 20.52 80.7031 2.376C80.7031 1.944 83.7241 1.944 89.9818 2.376C89.7661 15.12 89.7661 31.752 89.7661 52.056Z" fill="white"/>
                <path d="M117.171 52.0563L113.287 52.4883C112.208 53.3523 109.618 54.0003 105.518 54.0003C100.987 54.0003 97.7502 52.9203 96.0239 50.5443C94.2976 48.6003 93.6503 45.1443 93.6503 40.6083L93.866 13.8243C93.866 8.42431 94.7292 4.75231 96.4555 2.80831C97.966 1.08031 100.771 0.216309 104.871 0.216309C109.834 0.216309 113.071 1.51231 114.797 3.88831C116.092 5.61631 116.739 8.64031 116.739 12.7443C116.955 16.6323 116.955 19.4403 116.955 21.3843H108.108C108.108 18.7923 107.892 16.2003 107.892 13.6083C107.676 10.1523 106.813 8.42431 105.518 8.42431C103.792 8.42431 103.145 10.1523 103.145 13.6083V41.2563C103.145 41.9043 103.361 42.7683 103.792 43.8483C104.224 44.9283 104.871 45.5763 105.734 45.5763C107.46 45.5763 108.324 43.2003 108.324 38.4483V34.9923C108.324 34.3443 108.108 34.1283 107.892 34.1283H106.382L106.813 26.1363C109.187 26.1363 112.423 26.1363 116.523 26.1363L117.602 27.2163C117.171 26.7843 117.171 34.7763 117.171 52.0563Z" fill="white"/>
                <path d="M146.733 54.0002H137.455L136.807 49.6802C136.376 47.5202 135.944 45.7922 135.728 44.0642C135.728 43.8482 135.512 43.6322 135.081 43.6322H132.491C132.276 43.6322 132.06 43.8482 131.844 44.0642C131.413 46.6562 130.981 49.6802 130.334 53.1362H120.839C122.35 44.2802 123.86 35.2082 125.371 26.1362L130.549 0.216199C132.923 0.00019922 135.297 0.00019922 137.67 0.216199L142.633 26.3522C143.928 35.4242 145.223 44.7122 146.733 54.0002ZM135.297 35.4242C134.649 30.4562 134.218 27.8642 134.002 27.8642C133.786 27.8642 133.57 28.9442 133.139 31.1042C132.923 32.6162 132.707 33.9122 132.707 35.4242C132.707 35.6402 132.923 35.6402 133.355 35.6402C133.786 35.6402 134.218 35.6402 134.649 35.6402C135.081 35.6402 135.297 35.6402 135.297 35.4242Z" fill="white"/>
                <path d="M173.706 1.72808L173.275 52.0561L163.996 52.4881C162.486 46.0081 160.759 38.4481 159.033 30.0241C158.817 29.3761 158.601 28.9441 158.17 28.9441C157.954 28.9441 157.738 29.3761 157.738 30.2401C157.738 31.1041 157.738 34.3441 157.738 39.5281C157.738 44.7121 157.738 48.8161 157.738 51.8401L149.107 52.0561C148.891 46.0081 148.891 36.7201 148.891 24.1921C148.891 12.3121 149.107 4.53608 149.323 1.29608L159.249 1.08008C161.407 11.6641 162.917 18.5761 163.78 21.6001C164.212 22.6801 164.428 23.3281 164.643 23.3281C164.859 23.3281 165.075 22.6801 165.075 21.3841C164.859 17.9281 164.859 11.0161 164.859 1.08008H173.706V1.72808Z" fill="white"/>
                <path d="M200.032 1.72808L199.6 52.0561L190.322 52.4881C188.811 46.0081 187.085 38.4481 185.359 30.0241C185.143 29.3761 184.927 28.9441 184.496 28.9441C184.28 28.9441 184.064 29.3761 184.064 30.2401C184.064 31.1041 184.064 34.3441 184.064 39.5281C184.064 44.7121 184.064 48.8161 184.064 51.8401L175.433 52.0561C175.217 46.0081 175.217 36.7201 175.217 24.1921C175.217 12.3121 175.433 4.53608 175.648 1.29608L185.574 1.08008C187.732 11.6641 189.243 18.5761 190.106 21.6001C190.537 22.6801 190.753 23.3281 190.969 23.3281C191.185 23.3281 191.401 22.6801 191.401 21.3841C191.185 17.9281 191.185 11.0161 191.185 1.08008H200.032V1.72808Z" fill="white"/>
                <path d="M215.784 22.6801C215.784 23.9761 215.784 24.6241 215.784 25.0561C215.353 28.0801 214.489 32.6161 212.763 38.2321C212.332 38.2321 211.468 38.2321 210.39 38.4481C209.526 38.4481 208.879 38.4481 208.448 38.4481L205.858 25.0561L205.642 22.8961C205.642 21.3841 205.642 18.3601 205.642 14.0401C205.642 7.99214 205.642 3.88814 205.642 1.72814L215.568 1.29614C215.568 4.53614 215.568 8.64014 215.784 13.8241C215.784 18.7921 215.784 21.8161 215.784 22.6801ZM215.784 53.3521H205.858C205.642 50.1121 205.642 47.3041 205.642 44.7121H215.784C216 46.4401 216 49.2481 215.784 53.3521Z" fill="#46B8AC"/>
                </g>
                <defs>
                <clipPath id="clip0">
                <rect width="216" height="54" fill="white"/>
                </clipPath>
                </defs>
            </svg>                                                                  
          </div>
          <div class="content-bloc__content">
            <div class="band_testimony">
                <div class="band_testimony__comics">
                    <img src="assets/images/bands/gazigann/bd.png" alt="Small Bands BD">
                </div>
                <div class="band_testimony__content">
                    <h3>Tome 1</h3>
                    <h2>"Shopping Center"</h2>
                    <div class="band_testimony__content__author">
                        <div class="band_testimony__content__author--avatar">
                            <img src="assets/images/bands/gazigann/avatar.png" alt="Alexis Dufour">
                        </div>
                        <div class="band_testimony__content__author--name">
                            <p class="band_testimony__content__author--name--title">Témoignage du guitarise</p>
                            <p class="band_testimony__content__author--name--name">Alexis Dufour</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="story">
                <p>Jouer dans un shopping center, c’est une première! … et une dernière… On a été livrés à nous-mêmes dès l’arrivée et on dû se débrouiller pour l’installation de la scène et annoncer notre propre concert. Bah, pas trop grave tant qu’il y a de l’ambiance! Mais quelle a été notre joie de voir que <b>nos amis ne pouvaient même pas rentrer avec leurs consommations alors qu’on était en pleine fête de la musique</b> dans toute la ville… Le meilleur, c’était quand même le <b>gars du stand de casseroles d’à côté qui est monté sur scène pour faire sa pub!</b></p>
                <p>Bon, allez, on arrête de râler, je suis sûr qu’on a quand même eu une mamie qui achetait son poireau, un papy qui avait oublié son appareil auditif et un pilier de comptoir bien alcoolisé qui sont tous sûrement devenus <b>des fans incontestables de Gazigann</b> après ce concert inoubliable!
                </p>
            </div>
            <div class="gallery">
                <img src="/assets/images/bands/gazigann/gallery_thumbnail_1.jpg" (click)="showGallery(0)">
                <img src="/assets/images/bands/gazigann/gallery_thumbnail_2.jpg" (click)="showGallery(1)">
            </div>
          </div>
       </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Speed Drawing</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/5al4VrPUvtY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc">
            <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/6669207&color=%2330c2b1&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/gazigann" title="Gazigann" target="_blank" style="color: #cccccc; text-decoration: none;">Gazigann</a> · <a href="https://soundcloud.com/gazigann/down" title="Down" target="_blank" style="color: #cccccc; text-decoration: none;">Down</a></div>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/bands/gazigann/joker.png" alt="Beer" class="top-right">
    </div>
</div>