import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-group-gbb',
  templateUrl: './group-gbb.component.html',
  styleUrls: ['./group-gbb.component.scss']
})
export class GroupGbbComponent implements OnInit {

  band: Band;

  constructor(private gallery: Gallery) { }

  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/bands/gbb/gallery_zoom_1.jpg'},
            {path: '/assets/images/bands/gbb/gallery_zoom_2.jpg'},
            {path: '/assets/images/bands/gbb/gallery_zoom_4.jpg'}
        ],
        index
    };
    this.gallery.load(prop);
  }

  ngOnInit(): void {
    this.band = {
      name: "Gustave Brass Band",
      genre: "Folklore",
      biography: "Des cuivres, du swag, de magnifiques marcels et une ambiance de folie… Cette équipe de feu forme un véritable mur de son dont vous vous souviendrez",
      image: "assets/images/bands/gbb/banner.png",
      logo: "assets/images/bands/gbb/logo.png",
      title: "Gustave Brass Band",
      backgroundImage: "/assets/images/backgrounds/grass.jpg",
      teamImage: "/assets/images/bands/gbb/smallbands-gbb-band.png",
      websiteURL: "https://www.gustavebrassband.com/",
      facebookURL: "https://www.facebook.com/gustavebrassband",
      instagramURL: "https://www.instagram.com/gustavebrassband/",
      youtubeURL: "https://www.youtube.com/channel/UCSaVn2xTdvi1yfi8scZdI1A",
      spotifyURL: "https://open.spotify.com/artist/4uEZN4W707DvSd3CtIeI1w?si=Y9SF86taQ12V2lznP6QVqQ&dl_branch=1",
      bandcampURL: "https://gustavebrassband.bandcamp.com/",
    }
  }
}
