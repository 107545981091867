<div class="wrapper">
    <div class="wrapper__content">
       <div class="content-bloc">
          <div class="content-bloc__header">
            <svg width="123" height="89" viewBox="0 0 123 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M37.0355 1.08228C36.624 2.11104 36.0067 3.96281 35.1837 6.43184C34.155 9.72388 33.5377 11.7814 33.332 13.0159C33.332 13.2217 33.332 13.6332 33.332 14.2504C33.332 17.954 33.332 22.2748 33.332 27.4186H28.3939C28.3939 23.5093 28.5997 18.9827 28.5997 14.0447C28.5997 13.8389 28.5997 13.6332 28.3939 13.0159C27.3652 9.51812 26.1306 5.40308 24.4846 0.465027L29.6284 0.670779C29.8342 1.69954 30.2457 3.34556 30.6572 5.19733C30.6572 6.22609 30.8629 6.63759 30.8629 6.63759C30.8629 6.63759 31.0687 6.22609 31.0687 5.40308C31.2744 4.37432 31.4802 2.7283 31.8917 0.876531H37.0355C37.0355 0.876531 37.0355 0.876531 37.0355 1.08228Z" fill="white"/>
                <path d="M48.7633 22.892C48.7633 26.8013 46.9115 28.8588 43.208 28.8588C41.3562 28.8588 39.9159 28.4473 39.0929 27.4186C38.2699 26.5956 37.8584 24.9495 37.8584 23.0978V6.6376C37.8584 4.16857 38.2699 2.3168 39.2987 1.28804C40.1217 0.465032 41.3562 0.0535278 43.208 0.0535278C45.0597 0.0535278 46.2942 0.465032 47.1172 1.28804C48.146 2.3168 48.7633 3.96282 48.7633 6.43185V22.892V22.892ZM44.4425 21.4518V7.25486C44.4425 5.81459 44.031 5.19733 43.4137 5.19733C42.5907 5.19733 42.3849 5.81459 42.3849 7.25486V21.4518C42.3849 22.2748 42.3849 22.6863 42.5907 22.892C42.7965 23.0978 43.0022 23.0978 43.4137 23.0978C44.031 23.0978 44.4425 22.4805 44.4425 21.4518Z" fill="white"/>
                <path d="M62.9601 0.670779V22.2748C62.9601 23.715 62.7543 24.7438 62.1371 25.7725C61.1083 27.6243 59.4623 28.4473 56.9932 28.4473C54.3185 28.4473 52.4667 27.8301 51.6437 26.3898C51.0264 25.5668 50.8207 23.9208 50.8207 21.4518V0.670779L55.553 0.465027C55.553 1.28804 55.553 4.78582 55.553 11.3699C55.553 17.7482 55.553 21.0402 55.553 20.8345C55.553 22.6863 55.9645 23.5093 56.9932 23.5093C57.8163 23.5093 58.2278 22.6863 58.2278 20.8345V0.670779H62.9601Z" fill="white"/>
                <path d="M76.3341 27.6243H71.6018L69.5443 17.131C69.3385 17.131 69.3385 17.131 69.1327 17.131C68.927 17.131 68.927 17.5425 68.927 17.954C68.927 21.6575 68.927 24.7438 68.927 27.6243H64.6062V0.0535222H70.573C72.0133 -0.15223 73.042 0.259274 74.0708 0.876531C75.0996 1.49379 75.7168 2.52255 75.7168 3.55131V10.3411C75.7168 11.5756 75.3053 12.3987 74.6881 13.2217C74.2766 13.8389 73.6593 14.2504 73.042 14.4562C72.8363 14.4562 72.8363 14.6619 72.8363 14.6619C72.8363 14.6619 74.0708 18.9827 76.3341 27.6243ZM71.6018 6.43184C71.6018 4.99158 71.1903 4.16857 70.1615 4.16857C69.9558 4.16857 69.75 4.16857 69.5443 4.16857C69.3385 4.16857 69.1327 4.37432 69.1327 4.78582V10.9584C69.1327 11.5756 69.3385 11.9872 69.5443 11.9872C69.75 11.9872 69.75 11.9872 69.9558 11.9872C70.3673 11.9872 70.7788 11.7814 70.9845 11.1641C71.396 10.5469 71.6018 8.90087 71.6018 6.43184Z" fill="white"/>
                <path d="M22.2212 81.3257C22.2212 84.2062 20.781 86.4695 17.9004 87.704H0V37.0889L12.1394 36.8832C14.6084 36.8832 16.8717 37.9119 18.7235 39.7637C20.5752 41.6155 21.3982 43.8788 21.3982 46.3478V52.9318C21.3982 55.4009 21.1925 57.2526 20.5752 58.2814C19.958 59.7217 18.7235 61.1619 16.6659 62.1907C18.1062 62.808 19.3407 64.0425 20.3695 65.4827C21.604 67.1288 22.2212 68.7748 22.2212 70.6265V81.3257V81.3257ZM12.7566 49.2283C12.7566 47.788 12.7566 46.7593 12.3451 46.142C11.9336 45.319 11.1106 44.9075 9.87611 44.9075C9.25885 44.9075 8.64159 44.9075 8.43584 44.9075C8.23009 44.9075 8.02434 45.1133 8.02434 45.5248C8.02434 47.5823 8.02434 51.4916 8.02434 57.0469C8.23009 57.4584 8.23009 57.8699 8.64159 57.8699C9.4646 57.8699 10.2876 57.6642 11.3164 57.2526C12.3451 56.6354 12.7566 56.0181 12.7566 54.9894V49.2283ZM13.1681 74.7416V71.038C13.1681 68.569 11.7279 67.5403 8.84735 67.5403C8.64159 67.5403 8.43584 67.746 8.43584 68.1575V79.4739C8.43584 80.0911 8.64159 80.2969 9.0531 80.2969H9.87611C12.1394 80.0911 13.1681 78.4451 13.1681 74.7416Z" fill="white"/>
                <path d="M49.9979 88.7327L41.1505 88.5269L40.5333 84.4119C40.1218 82.3544 39.7103 80.7084 39.5045 79.0623C39.5045 78.8566 39.2988 78.6508 38.8873 78.6508H36.4182C36.2125 78.6508 36.0067 78.8566 35.801 79.0623C35.3895 81.5314 34.978 84.4119 34.3607 87.7039L25.3076 87.4982C26.7479 79.0623 28.1881 70.4207 29.6284 61.7791L34.5665 37.0889C36.8297 36.8831 39.093 36.8831 41.3563 37.0889L46.0886 61.9849C47.5289 70.8322 48.7634 79.6796 49.9979 88.7327ZM39.093 70.8322C38.4758 66.0999 38.0643 63.6309 37.8585 63.6309C37.6527 63.6309 37.447 64.6597 37.0355 66.7172C36.8297 68.1575 36.624 69.392 36.624 70.8322C36.624 71.038 36.8297 71.038 37.2412 71.038C37.6527 71.038 38.0643 71.038 38.4758 71.038C38.8873 71.2437 39.093 71.038 39.093 70.8322Z" fill="white"/>
                <path d="M75.9226 38.9407L75.5111 86.881L66.6638 87.2925C65.2235 81.1199 63.5775 73.9186 61.9315 65.8943C61.7257 65.277 61.52 64.8655 61.1085 64.8655C60.9027 64.8655 60.6969 65.277 60.6969 66.1C60.6969 66.923 60.6969 70.0093 60.6969 74.9474C60.6969 79.8854 60.6969 83.7947 60.6969 86.6753L52.4669 86.881C52.2611 81.1199 52.2611 72.2726 52.2611 60.339C52.2611 49.0226 52.4669 41.6155 52.6726 38.5292L62.1372 38.3235C64.1947 48.4053 65.635 54.9894 66.458 57.8699C66.8695 58.8987 67.0753 59.516 67.281 59.516C67.4868 59.516 67.6925 58.8987 67.6925 57.6642C67.4868 54.3722 67.4868 47.7881 67.4868 38.3235H75.9226V38.9407Z" fill="white"/>
                <path d="M99.1725 70.8322C99.1725 82.5601 95.469 88.1154 87.8561 87.7039H78.803L78.5973 38.1176V37.2946C80.4491 37.0889 83.9468 37.0889 88.6791 37.0889C92.3827 37.0889 95.0575 38.9406 96.9092 42.6442C98.3495 45.5247 98.9668 49.2282 98.9668 53.9605V70.8322H99.1725ZM91.1482 68.569V52.9318C91.1482 51.08 90.7367 49.434 89.7079 47.9937C88.6791 46.5535 87.6504 45.7305 86.6216 45.7305C86.2101 45.7305 86.2101 45.9362 86.2101 46.5535C86.2101 46.7592 86.2101 50.8743 86.2101 59.1044C86.2101 67.3344 86.2101 73.9185 86.2101 78.8566C86.2101 79.4738 86.4159 79.8853 86.6216 79.8853C87.6504 79.8853 88.6791 78.8566 89.5022 76.3875C90.5309 73.9185 91.1482 71.4495 91.1482 68.569Z" fill="white"/>
                <path d="M122.011 44.9074L121.805 58.2813C121.805 60.9561 120.982 63.4251 119.542 65.2769C118.307 66.9229 117.073 67.7459 115.838 67.7459C115.427 67.7459 115.221 68.1574 115.221 68.7747C115.221 68.9805 115.221 69.5977 115.221 70.8322C115.221 71.861 115.221 72.4782 115.221 73.0955C115.221 73.507 112.958 73.7128 108.431 73.3012C108.431 72.4782 108.431 68.5689 108.431 61.7791C108.431 61.1619 108.637 60.7504 109.254 60.7504C110.077 60.5446 110.489 60.3389 110.9 60.3389C111.518 60.1331 112.135 59.7216 112.752 59.1043C113.575 58.2813 113.987 54.5778 113.987 48.4052C113.987 46.142 113.164 45.1132 111.518 45.1132C109.872 45.1132 108.843 46.142 108.843 48.4052L108.637 52.1088C103.699 52.3145 101.23 52.5203 101.23 52.1088C101.23 51.6973 101.23 50.0512 101.436 46.965C101.436 43.4672 102.464 40.9982 104.111 39.5579C105.757 38.3234 108.431 37.7061 112.135 37.7061C114.81 37.7061 117.279 38.3234 119.13 39.7636C120.982 40.7924 122.011 42.6442 122.011 44.9074ZM115.633 87.9097C110.489 88.3212 107.814 88.1154 107.814 87.2924C107.814 85.4406 107.814 82.9716 107.814 80.2968C107.814 79.8853 110.283 79.8853 115.633 80.5026C115.633 81.9428 115.838 84.4119 115.633 87.9097Z" fill="#46B8AC"/>
            </svg>                                        
          </div>
          <div class="content-bloc__content">
            <div class="qualifio_campaign" style="position: relative;">
                <div id="qualifio_insert_place_863335" class="qualifio_iframe_wrapper"></div>
                <!-- Material Design Spinner -->
                <div class="spinner-wrapper">
                    <div class="spinner">
                        <div class="inner">
                            <div class="gap"></div>
                            <div class="left">
                                <div class="half-circle"></div>
                            </div>
                            <div class="right">
                                <div class="half-circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Material Design Spinner -->
            </div>
          </div>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/global/crayon.png" alt="Crayon" class="top-right">
        <img src="assets/images/global/smallbands_gustave_brass_band.png" alt="Small Bands croquis" class="bottom-right">
        <img src="assets/images/global/smallbands_baraka.png" alt="Small Bands croquis" class="top-left">
    </div>
</div>