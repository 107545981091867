
<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <img src="assets/images/bands/kidnoize/title.svg" alt="Kid Noize">                                              
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/kidnoize/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"On va faire sans"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/kidnoize/avatar.png" alt="Kid Noize">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage du DJ</p>
                              <p class="band_testimony__content__author--name--name">Kid Noize</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p>Cette anecdote remonte à un bon moment maintenant ! <b>C'était au Zénith de Paris en 2015</b>, où j'ai eu la chance d'assurer la <b>première partie de Prodigy</b> ! Ça a été un coup dur pour tout le monde de se retrouver avec le strict minimum pour la prestation. On a vraiment galéré à charger tous les décors de scène dans nos voitures, pour finalement ne rien en faire... et nous voilà dans une salle aussi incroyable, sans pouvoir profiter de l'installation sonore et lumineuse.</p>
                <p>Enfin, <b>ça reste un super souvenir</b>, et maintenant j'ai même des planches de BD qui immortalisent cette situation ! C'est toujours sympa de se remémorer ces moments-là.</p>
                <img src="assets/images/bands/kidnoize/ticket.jpg" alt="ticket">
              </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Ocean</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/NZgV5_JmRzE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc bloc-versatile">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/1nSBihjWQgnAwxnd65XPdN?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
       <img src="assets/images/bands/kidnoize/deco1.png" alt="Album" class="top-right">
       <img src="assets/images/bands/kidnoize/deco2.png" alt="Album" class="bottom-left">
    </div>
</div>