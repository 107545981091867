import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-group-shaarghot',
  templateUrl: './group-shaarghot.component.html',
  styleUrls: ['./group-shaarghot.component.scss']
})
export class GroupShaarghotComponent implements OnInit {

  band: Band;

  constructor(private gallery: Gallery) { }

  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/bands/shaarghot/gallery_zoom_1.jpg'}
        ],
        index
    };
    this.gallery.load(prop);
  }

  ngOnInit(): void {
    this.band = {
      name: "Shaârghot",
      genre: "Electro Metal Punk",
      biography: "Sorti tout droit d'un univers dystopique où se mêlent le <b>cyberpunk</b> et le <b>post-apocalyptique</b>, le Shaârghot, créature délirante fruit d'une expérience ratée, sème le chaos dans la cité du Great Eye.<br/><br/>Plus qu'un groupe de musique, <b>Shaârghot est avant tout une histoire et un concept qui prend vie au travers de leurs concerts endiablés</b>. Des costumes post-apocalyptiques, un metal martial aussi dansant que percutant, des beats dancefloor où se mêlent des synthés acides et ravageurs, tels sont les ingrédients qui composent l'univers scénique du groupe.<br/><br/>L'appétit du monstre semble sans fin, car après avoir ouvert à maintes reprises pour de nombreux artistes de renom comme <b>MINISTRY // LITTLE BIG // PUNISH YOURSELF ou encore HOCICO</b>, Shaârghot continue son ascension à grands coups de batte de baseball !",
      image: "assets/images/bands/shaarghot/banner.jpg",
      logo: "assets/images/bands/shaarghot/logo.png",
      title: "Shaârghot",
      teamImage: "/assets/images/bands/shaarghot/small-bands-shaarghot-band.png",
      websiteURL: "https://www.shaarghot.fr/",
      facebookURL: "https://www.facebook.com/shaarghot",
      instagramURL: "https://www.instagram.com/shaarghot_official/",
      youtubeURL: "https://www.youtube.com/channel/UCoNxJTk1tc12iN2ADTy7ZdQ",
      spotifyURL: "https://open.spotify.com/artist/0wxpqCSmhtwnRXoWPoHAcj?si=oRmsFrzCTkecIEYREIg0pA",
    }
  }

}
