import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-group-yves-paquet',
  templateUrl: './group-yves-paquet.component.html',
  styleUrls: ['./group-yves-paquet.component.scss']
})
export class GroupYvesPaquetComponent implements OnInit {

  band: Band;

  constructor(private gallery: Gallery) { }
  
  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/bands/yvespaquet/gallery_zoom_1.jpg'},
            {path: '/assets/images/bands/yvespaquet/gallery_zoom_2.jpg'},
            {path: '/assets/images/bands/yvespaquet/gallery_zoom_3.jpg'},
            {path: '/assets/images/bands/yvespaquet/gallery_zoom_4.jpg'}
        ],
        index
    };
    this.gallery.load(prop);
  }

  ngOnInit(): void {
    this.band = {
      name: "Yves Paquet",
      genre: "Pop/Soul",
      biography: "Né au Brésil, Yves Paquet est un <b>auteur-compositeur-interprète de Pop/Folk/Soul</b>. Yves Luiz Paulo Paquet a commencé à créer son propre son sur la <b>scène Indie au Brésil</b>. Ce qui a suivi a été une période intensive au cours de laquelle il a <b>collaboré avec les stars brésiliennes Alok, Vintage Culture</b> et d'autres groupes de musique internationaux <b>Barely Alive, Airplane et The Subs</b> avec lesquels il a marqué son premier <b>numéro 1 dans les Belgian Dance Charts</b>. Après avoir signé avec <b>Universal Music</b> pour la sortie de son premier album <b>'Every Now And Then'</b>, Yves revient pour la première fois en tant qu'artiste solo.",
      image: "assets/images/bands/yvespaquet/banner.jpg",
      logo: "assets/images/bands/yvespaquet/logo.png",
      title: "Yves Paquet",
      teamImage: "/assets/images/bands/yvespaquet/small-bands-yves-paquet-band.jpg",
      facebookURL: "https://www.facebook.com/YvesPaquetMusic",
      instagramURL: "https://www.instagram.com/yvespaquetmusic/",
      youtubeURL: "https://www.youtube.com/channel/UC0EUzOpMIWjrz6EtV9xuySQ",
      spotifyURL: "https://open.spotify.com/artist/7rIXWoVhS7nzMxupPhKmum?si=PNdmFijeTduuk0NURRuREA",
      websiteURL: "https://linktr.ee/yvespaquet",
    }
  }

}
