import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';

@Component({
  selector: 'app-group-chump',
  templateUrl: './group-chump.component.html',
  styleUrls: ['./group-chump.component.scss']
})
export class GroupChumpComponent implements OnInit {

  band: Band;

  constructor() { }

  ngOnInit(): void {
    this.band = {
      name: "Chump",
      genre: "Punk Rock",
      biography: "<b>Chump</b> est un groupe de punkrock / skate punk créé à la fin des années 90 et qui a splitté en 2005 après 4 démos et albums. Ils sont de retour depuis 2017, après un break de 11 ans, avec un EP (“Ready to strike”) sorti en 2017 et un album 'Welcome To The Punk Rock Family' sorti en 2018. <br/>Depuis 2018, sous son nouveau lineup (Niko – chant / guitare, Romz – chant / basse, Gilles batterie), le groupe donne 70+ concerts par an et tourne en France, Suisse, Espagne, UK, Canada, USA, Danemark, Norvège, Suède, … <br/>Passer sa vie dans le van et balancer du skate punk mélo dans les clubs et les cafés concerts, c’est la raison d’être de <b>Chump</b>.",
      image: "assets/images/bands/chump/banner.jpg",
      logo: "assets/images/bands/chump/logo.png",
      title: "Chump",
      teamImage: "/assets/images/bands/chump/small-bands-chump-band.png",
      facebookURL: "https://www.facebook.com/punkrockchump",
      instagramURL: "https://www.instagram.com/punkrockchump/",
      youtubeURL: "https://www.youtube.com/@chumppunkrock5006",
      spotifyURL: "https://open.spotify.com/artist/5XtoiYH5JtdFYse3vB85zJ?si=f68_Ce5fTPS3ZQVx1p20_g",
    }
  }

}
