import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';
import { Gallery } from 'angular-gallery';


@Component({
  selector: 'app-group-gazigann',
  templateUrl: './group-gazigann.component.html',
  styleUrls: ['./group-gazigann.component.scss']
})
export class GroupGazigannComponent implements OnInit {

  band: Band;
  
  constructor(private gallery: Gallery) { }

  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/bands/gazigann/gallery_zoom_1.jpg'},
            {path: '/assets/images/bands/gazigann/gallery_zoom_2.jpg'}
        ],
        index
    };
    this.gallery.load(prop);
  }

  ngOnInit(): void {
    this.band = {
      name: "Gazigann",
      genre: "Rock",
      biography: "<b>Créé en 2006</b>, ce groupe originaire de Chaumont-Gistoux, était à la base un <b>Power Trio formé par 3 jeunes frères (Simon, Antoine et Julien)</b>. <br/> Cette même année, Gazigann monte sur les planches pour faire ses premières scènes. S'en suivront quelques belles expériences comme <b>l'Inc'rock, le Jyva'zik, le Rock bless you, etc.</b> <br/><br/>C'est en 2009 que le groupe est rejoint par un <b>deuxième guitariste (Alexis)</b> et continue son parcours musical. Gazigann c'est un subtil mélange de douceur et de puissance, de <b>rock vintage des années 70 et d'arrangements actuels!</b>",
      image: "assets/images/bands/gazigann/banner.png",
      logo: "assets/images/bands/gazigann/logo.png",
      title: "Gazigann",
      teamImage: "/assets/images/bands/gazigann/small-bands-gazigann-band.png",
      backgroundImage: "/assets/images/backgrounds/old-paper.jpg",
      facebookURL: "https://www.facebook.com/gazigannn",
      soundcloudURL: "https://soundcloud.com/gazigann",
    }
  }
}
