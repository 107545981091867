<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <img src="assets/images/bands/fleddymelculy/title.svg" alt="Fledy Melculy">                                           
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/fleddymelculy/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"Open Space"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/fleddymelculy/avatar.png" alt="Jeroen C.">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage du chanteur de Fleddy Melculy</p>
                              <p class="band_testimony__content__author--name--name">Jeroen C.</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p><b>Le 23 septembre 2016, une date gravée dans l'histoire de Fleddy Melculy !</b></p>
                <p>C’était le jour de la sortie de notre tout <b>premier album « Helgië »</b>. Notre label nous avait dit avoir dégoté un endroit incroyable pour la Release Party de l’album. Ils avaient loué une partie des anciens bureaux désaffectés dans la A-Tower à Anvers, un bâtiment qui allait être démoli peu de temps après. Plutôt original comme endroit de concert, donc autant dire qu’on était super enthousiastes.</p>
                <p>Le jour J de la Release, on est arrivés sur les lieux pour découvrir l’endroit <b>où allait, quelques heures après, se dérouler notre concert</b>. C’est là qu’on a découvert que le bureau était beaucoup trop petit… On aurait pu y glisser une scène ridicule et entasser à peine 100 personnes comme des sardines ! <b>Les responsables du A-Tower s’était tromper de lieu….</b></p>
                <p>C'est là qu'on a eu l'idée de génie de démolir les cloisons, le bâtiment allait de toute façon être détruit, donc bon… et puis on n’avait pas trop le choix ! Bien sûr, <b>tout cela devait se faire à la vitesse de la lumière, parce que quelques heures après, on devait monter sur scène et 500 fans surexcités allaient débarquer.</b></p>
                <div class="embedresize">
                    <div>
                        <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Ffleddymelculy%2Fvideos%2F1711498495842226%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                    </div>
                </div>
                <p><b>On avait oublié qu’une seule chose, il n’y avait pas d’airco, donc après 5 minutes du concert, l’endroit du concert avait déjà 80°!</b></p>
                <p>On a fini par passer une soirée mémorable, entourés de nos fans qui semblaient apprécier autant notre musique que notre talent pour la rénovation express.</p>
                <p>Une soirée qu’on va jamais oublier et dont les gens qui était là en parle toujours.</p>
                <p>Cool de voir cette histoire en version BD! Merci Small Bands!</p>
                <div class="embedresize">
                    <div>
                        <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Ffleddymelculy%2Fvideos%2F1713197479005661%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                    </div>
                </div>
              </div>
              <div class="gallery">
                  <img src="/assets/images/bands/fleddymelculy/gallery_thumbnail_1.jpg" (click)="showGallery(0)">
                  <img src="/assets/images/bands/fleddymelculy/gallery_thumbnail_2.jpg" (click)="showGallery(1)">
              </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing content-bloc content-bloc--speeddrawing__twocol">
        <div class="speedcol">
            <h1>FREDDIE</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/15oHEknEpB8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="speedcol">
            <h1>Apu van de nightshop</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/nUrx0jtHsgs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
       </div>
       <div class="content-bloc">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/4yMBnQTFuTAQkEoU1yKH5x?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
       <img src="assets/images/bands/fleddymelculy/deco1.png" alt="Album" class="top-right">
       <img src="assets/images/bands/fleddymelculy/deco2.png" alt="Album" class="bottom-left">
    </div>
</div>
