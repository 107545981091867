import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';

@Component({
  selector: 'app-group-versatile',
  templateUrl: './group-versatile.component.html',
  styleUrls: ['./group-versatile.component.scss']
})
export class GroupVersatileComponent implements OnInit {

  band: Band;

  constructor() { }

  ngOnInit(): void {
    this.band = {
      name: "Versatile",
      genre: "Black Metal",
      biography: "Versatile est venu au monde dans les terres de Genève. Ce projet musical suisse puise son énergie dans les arpèges violents et dissonants du <b>Black Metal</b> et des sons froids de la <b>musique industrielle</b>. Bercé par les cris lugubres de Hatred et par les riffs abrasifs de Cinis et Famine <i>(guitaristes)</i>, cette formation instaure un <b>univers visuel théâtral et unique</b> inspiré des Catacombes de Paris et de la célèbre Cour des Miracles. Sous la bannière de leur monde, leurs personnalités uniques vous invitent à les rejoindre sur scène où les larmes, la sueur et le sang vivent en un seul front.",
      image: "assets/images/bands/versatile/banner.jpg",
      logo: "assets/images/bands/versatile/logo.png",
      title: "Versatile",
      teamImage: "/assets/images/bands/versatile/small-bands-versatile-band.png",
      facebookURL: "https://www.facebook.com/versatilemetalband",
      instagramURL: "https://www.instagram.com/versatilemetalband/",
      youtubeURL: "https://www.youtube.com/c/Versatilemetalband",
      bandcampURL: "https://versatilemetal.bandcamp.com/releases?fbclid=IwAR0LdvBafTWhvImi4NJoJJgISuz8vh-zhhfcC7HYbflHuoqyFLkfg_H2JRE",
      websiteURL: "https://www.versatilemetalband.com",
    }
  }

}
