import { Component, Input, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';

@Component({
  selector: 'app-group-title',
  templateUrl: './group-title.component.html',
  styleUrls: ['./group-title.component.scss']
})
export class GroupTitleComponent implements OnInit {

  @Input() band: Band;
  
  constructor() { }

  ngOnInit(): void {
  }

}
