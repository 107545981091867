// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    firebaseConfig: {
        apiKey: "AIzaSyD6FuAa99QgjczLgB7_WpozNF6diaiaUo0",
        authDomain: "small-bands.firebaseapp.com",
        projectId: "small-bands",
        storageBucket: "small-bands.appspot.com",
        messagingSenderId: "6620546566",
        appId: "1:6620546566:web:47cb386d55439c66566587",
        measurementId: "G-XFFM3XDR6L"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
