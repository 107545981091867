
<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
               <img src="assets/images/bands/yvespaquet/title.svg" alt="Yves Paquet">                                              
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/yvespaquet/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"Oups..."</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/yvespaquet/avatar.png" alt="Yves Paquet">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage du chanteur</p>
                              <p class="band_testimony__content__author--name--name">Yves Paquet</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p>C'était lors d'un concert dans le cadre d'un <b>festival open air au Luxembourg</b>. Après quelques morceaux, j’ai aperçu une personne dans la foule qui agitait frénétiquement les mains. Il s'est avancé vers le devant de la scène, se présentant comme technicien, et m'a remis <b>une note avec le numéro de plaque d'une voiture qui bloquait les techniciens derrière la scène.</b></p>
                <p>Il me demandait d'annoncer au micro que le propriétaire de ladite voiture devait la déplacer d’urgence.</p>
                <p>J’ai regardé le numéro… et merde… <b>c'était ma propre voiture !</b></p>
                <p>J'ai interrompu le concert, fouillé dans mes affaires au fond de la scène pour trouver les clés, et je me suis précipité pour déplacer ma voiture.</p>
                <p>C’est sûr que je n'ai pas dû réfléchir à deux fois pour trouver une anecdote à envoyer pour la BD Small Bands - une situation comme ça, ça ne s’oublie pas… <b>la honte!</b></p>    
              </div>
              <div class="gallery">
                 <img src="/assets/images/bands/yvespaquet/gallery_thumbnail_1.jpg" (click)="showGallery(0)">
                 <img src="/assets/images/bands/yvespaquet/gallery_thumbnail_2.jpg" (click)="showGallery(1)">
                 <img src="/assets/images/bands/yvespaquet/gallery_thumbnail_3.jpg" (click)="showGallery(2)">
                 <img src="/assets/images/bands/yvespaquet/gallery_thumbnail_4.jpg" (click)="showGallery(3)">
              </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Frozen In Time</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/dcTvhXBl2lQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/7rIXWoVhS7nzMxupPhKmum?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/bands/yvespaquet/album.png" alt="Album" class="top-right">
        <img src="assets/images/bands/yvespaquet/album2.png" alt="Album" class="bottom-left">
    </div>
</div>