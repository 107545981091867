
<div class="wrapper wrapper-rudy">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <img src="assets/images/bands/rudylenners/title.svg" alt="Rudy Lenners">                                             
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/rudylenners/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"Jusqu'au bout!"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/rudylenners/avatar.png" alt="Rudy Lenners">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage de l'ex-batteur des Scorpions</p>
                              <p class="band_testimony__content__author--name--name">Rudy Lenners</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p>Pour ceux qui aiment passionnément les arachnides, sachez que je pique encore et encore mais cette fois par le biais de l’écriture.</p>
                <p>En effet, j’ai tout récemment écrit 2 ouvrages avec ma plume de gaucher pas trop maladroit.</p>
                <p><b>Tout d’abord :</b></p>
                <ul>
                    <li><b>"Achtung ! Ça va piquer…"</b> paru aux éditions Foliberté et distribué par Noir Dessin Prod.</li>
                </ul>
                <p><b>Ensuite :</b></p>
                <ul>
                    <li><b>"Piqûres de rappel"</b> et son pendant anglais <b>"Sting loving you"</b> parus aux éditions Camion Blanc</li>
                </ul>
                <div class="books">
                    <a href="https://www.noirdessinlaboutique.be/nouveautes/achtung-ca-va-piquer.html" target="_blank"><img src="assets/images/bands/rudylenners/livre2.png" alt="Rudy Lenners"></a>
                    <a href="https://www.fnac.com/a18234446/Rudy-Lenners-Rudy-Lenners" target="_blank"><img src="assets/images/bands/rudylenners/livre1.png" alt="Rudy Lenners"></a>
                </div>
                <p><b>Et pour en finir,</b> en tant que végétarien, j’espère avoir le temps d’écrire un dernier ouvrage culino-musical intitulé :</p>
                <p><b>"Lard sous toutes ses formes "!</b></p>
                <p>Mais vais-je en faim arriver à bon porc…</p>
              </div>
              <div class="gallery">
                  <img src="/assets/images/bands/rudylenners/gallery_thumbnail_1.jpg" (click)="showGallery(0)">
                  <img src="/assets/images/bands/rudylenners/gallery_thumbnail_2.jpg" (click)="showGallery(1)">
                  <img src="/assets/images/bands/rudylenners/gallery_thumbnail_3.jpg" (click)="showGallery(2)">                  
              </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Scorpions - Speedy's Coming (Tv Show 1974)</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/au_UTT9_x08" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc bloc-versatile">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/3uiZIT0XrEjZF9Y8OUIxnR?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/bands/rudylenners/album2.png" alt="Album" class="bottom-left">
        <img src="assets/images/bands/rudylenners/album.png" alt="Photo" class="top-right">
    </div>
</div>