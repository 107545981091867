import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-group-bugulnoz',
  templateUrl: './group-bugulnoz.component.html',
  styleUrls: ['./group-bugulnoz.component.scss']
})
export class GroupBugulnozComponent implements OnInit {

  band: Band;

  constructor(private gallery: Gallery) { }

  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/bands/bugulnoz/gallery_zoom_1.jpg'}
        ],
        index
    };
    this.gallery.load(prop);
  }


  ngOnInit(): void {
    this.band = {
      name: "Bugul Noz",
      genre: "Punk celtique",
      biography: "Bugul Noz est un groupe belge de Celtic Punk s’inspirant des sonorités venant de la musique punk et de la musique celtique, qu’elle soit irlandaise, bretonne ou écossaise. <br/>On retrouve, parmi ses influences, des formations telles que les Dropkick Murphys, The Mahones ou encore The Real Mckenzies mais aussi des groupes tels que Les Beruriers noirs et Trust. <br/>Après avoir mis du temps à trouver un équilibre dans sa formation, Bugul Noz sort son premier album éponyme en juillet 2017, le second ('Chat in the Freezer') en décembre 2020, et les défend maintenant sur les scènes de Belgique et d’ailleurs.",
      image: "assets/images/bands/bugulnoz/banner.jpg",
      logo: "assets/images/bands/bugulnoz/logo.png",
      title: "Bugul Noz",
      teamImage: "/assets/images/bands/bugulnoz/small-bands-bugul-noz-band.jpg",
      websiteURL: "https://bugulnoz.wixsite.com/bugul-noz",
      facebookURL: "https://www.facebook.com/BugulNozOfficial",
      instagramURL: "https://www.instagram.com/bugulnoz.official/",
      youtubeURL: "https://www.youtube.com/channel/UC1fUo3fuyHfxk3CQrUKOO6A",
      spotifyURL: "https://open.spotify.com/artist/1DD0bA72i7L40njnm4rla4?si=PYj1JYkuRWi_fz5qIRNz9g",
    }
  }

}
