import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';

@Component({
  selector: 'app-group-kid-noize',
  templateUrl: './group-kid-noize.component.html',
  styleUrls: ['./group-kid-noize.component.scss']
})
export class GroupKidNoizeComponent implements OnInit {

  band: Band;

  constructor() { }

  ngOnInit(): void {
    this.band = {
      name: "Kid Noize",
      genre: "DJ Electropop",
      biography: "Les ados le savent : Kid Noize est bien plus qu’un musicien, DJ et producteur. C’est un personnage auquel ils s’identifient dans l’univers cosplay, du transforming et des super héros qui les réunit aujourd’hui.<br/><b>Kid Noize est une fiction dans le monde réel</b>, un héros venu d’un univers parallèle, le coffre de la voiture rempli d’objets venus d’un passé qui a pour but de leur rappeler qui ils sont vraiment. <br/>La maison de Kid Noize est un véritable musée où s’accumulent ses collections de jouets anciens, vinyles, platines, DVD, jeux vidéo.<br/><b>Artiste pluridisciplinaire.</b> Derrière une caméra, une platine de DJ, un synthétiseur ou une table de mixage, Kid Noize est aussi habile sur six cordes de guitare, une planche à dessin que sur l’écran plat d’un ordinateur.<br/><b>Un artiste d’un genre rare mettant ses histoires en scène dans des albums habités par des sonorités aussi novatrices qu’innombrables.<b/>",
      image: "assets/images/bands/kidnoize/banner.jpg",
      logo: "assets/images/bands/kidnoize/logo.png",
      title: "Kid Noize",
      teamImage: "/assets/images/bands/kidnoize/small-bands-kidnoize-band.png",
      facebookURL: "https://www.facebook.com/KidNoizeWorld/",
      twitterURL: "https://twitter.com/kidnoizeworld",
      instagramURL: "https://www.instagram.com/kidnoizeworld/",
      youtubeURL: "https://www.youtube.com/user/blackgizahrecords",
      websiteURL: "https://kidnoize.com/",
    }
  }

}
