
<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <img src="assets/images/bands/thomasfrankhopper/title2.svg" alt="Thomas Frank Hopper">                                                  
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/thomasfrankhopper/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"Sports extrêmes"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/thomasfrankhopper/avatar.png" alt="Thomas Frank Hopper">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage du chanteur guitarise de TFH</p>
                              <p class="band_testimony__content__author--name--name">Thomas</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p>Max, notre joueur de synthé qui clôture magistralement notre anecdote, <b>à quand même fait le concert jusqu’au bout, avec son poignet cassé</b>. À ce moment-là, il ne savait même pas que c'était cassé. Dans l'adrénaline et l'euphorie du moment, il a surpassé sa douleur pour terminer le concert. Il a même continué à festoyer avec nous toute la soirée...</p>
                <p>Ce n'est que le lendemain qu'il a décidé d'aller à l'hôpital, parce que la douleur ne diminuait pas. C'est là que le médecin lui a annoncé qu'il s'agissait d'une fracture. Malgré le plâtre qu'il devait garder pendant plus d'un mois, <b>Max était en mode "The Show must go on!"</b> et a quand même décidé - et réussi - à assurer les concerts qui ont suivi peu après.</p>
                <br/><p><b>Ce que Max ne sait pas encore</b>, et découvrira en lisant ce témoignage, c'est que nous avons prévu de lui offrir ce fameux saut en parachute ! </p>
                <p><i>"Eh ouais Max, si tu nous lis en ce moment, prépare-toi à faire le grand saut, <b>et cette fois avec un parachute !</b>"</i></p>
              </div>
              <img src="assets/images/bands/thomasfrankhopper/ticket.png" alt="cadeau Thomas Frank Hopper">
            </div>
        </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Come Closer</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/xb6VNc_BFPY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/6aUCPKRiv9r4DPT0n3cYS9?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/bands/thomasfrankhopper/album.png" alt="Album" class="top-right">
        <img src="assets/images/bands/thomasfrankhopper/album2.png" alt="Album" class="bottom-left">
    </div>
</div>