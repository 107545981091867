import { Component, OnInit } from '@angular/core';
import { Gallery } from 'angular-gallery';

import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faFilm } from '@fortawesome/free-solid-svg-icons';
import { faMusic } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-concept',
  templateUrl: './concept.component.html',
  styleUrls: ['./concept.component.scss']
})
export class ConceptComponent implements OnInit {

  faCoffee = faCoffee;
  faFilm = faFilm;
  faMusic = faMusic;

  constructor(private gallery: Gallery) { }

  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/global/smallbands_cover.jpg'},
            {path: '/assets/images/global/smokebomb-chump-1.png'},
            {path: '/assets/images/global/smokebomb-chump-2.png'},
            {path: '/assets/images/global/smokebomb-chump-3.png'},
            {path: '/assets/images/global/smokebomb-chump-4.png'}
        ],
        index
    };
    this.gallery.load(prop);
  }
  

  ngOnInit(): void {
  }

}
