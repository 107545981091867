import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';

@Component({
  selector: 'app-group-evyka',
  templateUrl: './group-evyka.component.html',
  styleUrls: ['./group-evyka.component.scss']
})
export class GroupEvykaComponent implements OnInit {

  band: Band;
  
  constructor() { }

  ngOnInit(): void {
    this.band = {
      name: "Evyka",
      genre: "Electro-instrumental épic",
      biography: "Le projet Evyka a vu le jour en septembre 2020. Fondé par la compositrice et productrice Laetitia Piette, ce projet a évolué sous forme de collaboration avec Candice Delhez, violoniste. Ensemble, elles produisent de l'électro instrumental, s'inscrivant dans une dynamique du genre épique. De cette collaboration, est né un premier album, Ellipse, sorti en novembre 2021.",
      image: "assets/images/bands/evyka/banner.jpg",
      logo: "assets/images/bands/evyka/logo.png",
      title: "Evyka",
      teamImage: "/assets/images/bands/evyka/small-bands-evyka-band.png",
      facebookURL: "https://www.facebook.com/evykamusic",
      instagramURL: "https://www.instagram.com/evykamusic/",
      youtubeURL: "https://www.youtube.com/@evykamusic2020",
      spotifyURL: "https://open.spotify.com/artist/4yMBnQTFuTAQkEoU1yKH5x?si=UmUUbMfJTHyzEeLIfpdDAQ",
      websiteURL: "https://www.evykamusic.com",
      twitterURL: "https://twitter.com/evykamusic",
      tiktokURL: "https://www.tiktok.com/@evykamusic1",
    }
  }

}
