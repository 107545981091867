import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';

@Component({
  selector: 'app-group-la-jungle',
  templateUrl: './group-la-jungle.component.html',
  styleUrls: ['./group-la-jungle.component.scss']
})
export class GroupLaJungleComponent implements OnInit {

  band: Band;

  constructor() { }

  ngOnInit(): void {
    this.band = {
      name: "La Jungle",
      genre: "Techno/Trans Noise-Rock",
      biography: "Bien que définitivement un enfant du noise rock, La Jungle prend des morceaux de tout le spectre musical et les boucle en direct dans le but de créer des séquences compulsives et répétitives. Hypnotique, spontanée et volontiers lancinante, frisant parfois un groove sans fin, leur musique peut même inclure de brèves impulsions orientales de doom ou des détournements de métal bang au milieu d'un voyage épique de kraut rock.",
      image: "assets/images/bands/lajungle/banner.jpg",
      logo: "assets/images/bands/lajungle/logo.png",
      title: "La Jungle",
      teamImage: "/assets/images/bands/lajungle/small-bands-lajungle-band.png",
      facebookURL: "https://www.facebook.com/lajungleband",
      instagramURL: "https://www.instagram.com/la_jungle_band/",
      youtubeURL: "https://www.youtube.com/channel/UC8uEx7tJlNl4hXTO6H7o2eQ",
      websiteURL: "https://lajungleband.com/",
    }
  }

}
