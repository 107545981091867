
<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 90.8 54" style="enable-background:new 0 0 90.8 54;" xml:space="preserve">
           <style type="text/css">
               .st0{fill:#FFFFFF;}
               .st1{fill:#45B7AB;}
           </style>
           <g>
               <path class="st0" d="M23.6,51.2l-3.7,0.4C19,52.5,16.6,53,12.6,53c-4.3,0-7.3-1.1-9.1-3.3c-1.5-1.9-2.3-5.1-2.3-9.5l0.1-25.6
                   C1.4,9.5,2.3,6,3.9,4.1C5.4,2.5,8.1,1.7,12,1.7c4.6,0,7.8,1.2,9.5,3.5c1.3,1.7,1.9,4.5,1.9,8.5c0.1,3.7,0.2,6.5,0.1,8.2l-8.5,0.1
                   c-0.1-2.5-0.2-4.9-0.3-7.3c-0.3-3.3-1.1-5-2.3-5c-1.5,0-2.3,1.7-2.3,4.9v26.4c0,0.7,0.2,1.5,0.6,2.4c0.5,1.1,1.1,1.7,1.8,1.7
                   c1.7,0,2.5-2.3,2.5-6.8V35c0-0.5-0.2-0.8-0.5-0.8h-1.5l0.4-7.6c2.2,0,5.3,0,9.2,0.1l0.9,1.1C23.6,27.1,23.6,34.9,23.6,51.2z"/>
               <path class="st0" d="M48.7,45.8c0,3-1.4,5.1-4.3,6.5l-17.9-0.1L26.5,1.6l12.2-0.1c2.5,0,4.7,0.9,6.5,2.8c1.8,1.9,2.7,4,2.7,6.5
                   l-0.1,6.6c0,2.5-0.3,4.3-0.8,5.4c-0.6,1.5-1.9,2.8-3.9,4c1.4,0.7,2.6,1.8,3.7,3.3c1.2,1.7,1.8,3.4,1.8,5.1V45.8z M39.3,13.7
                   c0-1.4-0.1-2.4-0.4-3c-0.4-0.9-1.3-1.3-2.6-1.3c-0.7,0-1.2,0-1.4,0c-0.3,0-0.4,0.3-0.4,0.6c0,2.1,0.1,5.9,0.1,11.5
                   c0,0.5,0.1,0.8,0.4,0.8c0.8,0.1,1.7-0.1,2.7-0.7c0.9-0.5,1.4-1.3,1.5-2.2L39.3,13.7z M39.6,39.2l-0.1-3.7c0-2.4-1.5-3.6-4.3-3.6
                   c-0.3,0-0.4,0.2-0.4,0.7v11.2c0,0.5,0.2,0.8,0.7,0.8h0.9C38.6,44.7,39.7,42.9,39.6,39.2z"/>
               <path class="st0" d="M73.4,45.8c0,3-1.4,5.1-4.3,6.5l-17.9-0.1L51.2,1.6l12.2-0.1c2.5,0,4.7,0.9,6.5,2.8c1.8,1.9,2.7,4,2.7,6.5
                   l-0.1,6.6c0,2.5-0.3,4.3-0.8,5.4c-0.6,1.5-1.9,2.8-3.9,4c1.4,0.7,2.6,1.8,3.7,3.3c1.2,1.7,1.8,3.4,1.8,5.1V45.8z M64.1,13.7
                   c0-1.4-0.1-2.4-0.4-3c-0.4-0.9-1.3-1.3-2.6-1.3c-0.7,0-1.2,0-1.4,0c-0.3,0-0.4,0.3-0.4,0.6c0,2.1,0.1,5.9,0.1,11.5
                   c0,0.5,0.1,0.8,0.4,0.8c0.8,0.1,1.7-0.1,2.7-0.7c0.9-0.5,1.4-1.3,1.5-2.2L64.1,13.7z M64.4,39.2l-0.1-3.7c0-2.4-1.5-3.6-4.3-3.6
                   c-0.3,0-0.4,0.2-0.4,0.7v11.2c0,0.5,0.2,0.8,0.7,0.8h0.9C63.4,44.7,64.4,42.9,64.4,39.2z"/>
               <path class="st1" d="M88.9,23.3c0,1.2,0,1.9-0.1,2.2c-0.3,2.9-1.3,7.1-2.8,12.6c-0.4,0-1.2,0-2.3,0.1c-0.9,0-1.5,0-1.9,0l-2.5-12.8
                   l-0.3-2.1c0-1.4,0-4.2,0-8.5c0-5.8,0.1-9.7,0.1-11.7l9.4-0.4c0,3,0.1,7,0.2,11.9C88.8,19.6,88.9,22.5,88.9,23.3z M88.9,52.5h-9.5
                   c-0.1-3-0.2-5.8-0.1-8.1h9.6C89,45.9,89,48.6,88.9,52.5z"/>
           </g>
           </svg>
            </div>
            <div class="content-bloc__content">
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/gbb/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"Des scènes hors du commun"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/gbb/avatar.png" alt="Piet Mtlr">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage du percussioniste</p>
                              <p class="band_testimony__content__author--name--name">Piet Mtlr</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="story">
                  <p>Ces quelques planches de BD retracent des très très bons souvenirs qu’on a eu jusqu’à maintenant avec le groupe, que ce soit à Paris, en Bretagne, à Lourdes, … mais surtout <b>Berlin!</b> C’est une ville vraiment incroyable. On s'est promené un peu partout là-bas avec tous nos instruments! On s’est arrêtés à plein d’endroits improbables pour <b>faire des concerts, du cirque, des matchs de catch sur notre fameux tapis, enfin bref,…</b> tout ce qui nous passait par la tête pour capter l’attention des Berlinois. Et pour terminer notre séjour en beauté, on s’est rendu à un <b>parc nudiste</b>! Jouer nu, c’est une chose, mais avoir un public nu, ça <i>(…ça balote)</i> c’est très original! Après cet épisode, on a voulu garder un petit souvenir de ce concert insolite en faisant <b>notre propre calendrier de nu</b>, tels des pompiers sexy mettant en valeur nos incroyables corps musclés! Enfin…</p>
              </div>
              <div class="gallery">
                <img src="/assets/images/bands/gbb/gallery_thumbnail_1.jpg" (click)="showGallery(0)">
                <img src="/assets/images/bands/gbb/gallery_thumbnail_2.jpg" (click)="showGallery(1)">
                <img src="/assets/images/bands/gbb/gallery_thumbnail_4.jpg" (click)="showGallery(3)">
            </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Speed Drawing</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6zBg-Ag1ZDk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Musique Liquide (official video)</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/bmoIX-FmW2o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/4uEZN4W707DvSd3CtIeI1w?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/bands/gbb/trompette.png" alt="Trompette" class="top-right">
        <img src="assets/images/bands/gbb/marcel.png" alt="Marcel" class="bottom-left">
    </div>
</div>