import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';

@Component({
  selector: 'app-group-thomas-frank-hopper',
  templateUrl: './group-thomas-frank-hopper.component.html',
  styleUrls: ['./group-thomas-frank-hopper.component.scss']
})
export class GroupThomasFrankHopperComponent implements OnInit {

  band: Band;

  constructor() { }

  ngOnInit(): void {
    this.band = {
      name: "Thomas Frank Hopper",
      genre: "Rock blues",
      biography: "Thomas Frank Hopper c’est un riff à la slide branchée sur un ampli à lampes. Des mélodies rock qui suintent le blues. De la zik live sans artifice. Ce n’est pas un western spaghetti mais un stoemp à la BELGE sauce Dallas.",
      image: "assets/images/bands/thomasfrankhopper/banner.jpg",
      logo: "assets/images/bands/thomasfrankhopper/logo.png",
      title: "Thomas Frank Hopper",
      teamImage: "/assets/images/bands/thomasfrankhopper/small-bands-thomas-frank-hopper-band.png",
      facebookURL: "https://www.facebook.com/thomasfrankhopper/?ref=page_internal",
      instagramURL: "https://www.instagram.com/thomasfrankhopper/",
      youtubeURL: "https://www.youtube.com/c/ThomasFrankHopper",
      spotifyURL: "https://open.spotify.com/artist/6aUCPKRiv9r4DPT0n3cYS9?si=4zMHxMo-RHi2YJ0B0DbZpA",
      websiteURL: "https://www.thomasfrankhopper.com/",
    }
  }

}
