import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';

@Component({
  selector: 'app-group-smokebomb',
  templateUrl: './group-smokebomb.component.html',
  styleUrls: ['./group-smokebomb.component.scss']
})
export class GroupSmokebombComponent implements OnInit {

  band: Band;

  constructor() { }

  ngOnInit(): void {
    this.band = {
      name: "SmokeBomb",
      genre: "Harcodre Punk Hip Hop",
      biography: "Issus de la <b>scène Punk/Hardcore Belge</b> dans laquelle ils sont actifs depuis plus 15 ans, les cinq membres de Smokebomb décident de lancer leur nouveau projet pour combiner leur passion du Hardcore, du Punk, du Hip Hop, du Metal et de tout ce qui vient avec du groove. Avec <b>des racines ancrées dans les 90’s</b>, les influences principales sont (Hed)Pe, Downset, Snot, Rage Against The Machine, Suicidal Tendencies, Kottonmouth kings, Madball, Biohazard ou encore The Transplants. <br/><b>Rempli d'énergies positives, SmokeBomb est prêt à répandre un rapcore punk féroce partout dans le monde.</b>",
      image: "assets/images/bands/smokebomb/banner.jpg",
      logo: "assets/images/bands/smokebomb/logo.png",
      title: "SmokeBomb",
      teamImage: "/assets/images/bands/smokebomb/small-bands-smokebomb-band.png",
      facebookURL: "https://www.facebook.com/smokebombbxl",
      instagramURL: "https://www.instagram.com/smokebombbxl/",
      youtubeURL: "https://www.youtube.com/channel/UCJLsgIJDV7PkyaGClpv28XQ",
      bandcampURL: "https://smokebombbxl.bandcamp.com/",
    }
  }

}
