<div class="fuse-vertical-navigation-wrapper">

    <!-- Content -->
    <div
        class="fuse-vertical-navigation-content"
        fuseScrollbar
        [fuseScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}"
        #navigationContent>

        <a class="sidebar--logo" [routerLink]="['/concept']">
            <img src="assets/images/logos/smallbands_logo.svg" alt="Small Bands logo">
        </a>
        <a class="sidebar--bd" [routerLink]="['/order']">
            <img src="assets/images/global/smallbands_bd.png" alt="Small Bands BD">
        </a>

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Aside -->
                <fuse-vertical-navigation-aside-item
                    *ngIf="item.type === 'aside'"
                    [item]="item"
                    [name]="name"
                    [activeItemId]="activeAsideItemId"
                    [autoCollapse]="autoCollapse"
                    [skipChildren]="true"
                    (click)="toggleAside(item)"></fuse-vertical-navigation-aside-item>

                <!-- Basic -->
                <fuse-vertical-navigation-basic-item
                    *ngIf="item.type === 'basic'"
                    [item]="item"
                    [name]="name"></fuse-vertical-navigation-basic-item>

                <!-- Collapsable -->
                <fuse-vertical-navigation-collapsable-item
                    *ngIf="item.type === 'collapsable'"
                    [item]="item"
                    [name]="name"
                    [autoCollapse]="autoCollapse"></fuse-vertical-navigation-collapsable-item>

                <!-- Divider -->
                <fuse-vertical-navigation-divider-item
                    *ngIf="item.type === 'divider'"
                    [item]="item"
                    [name]="name"></fuse-vertical-navigation-divider-item>

                <!-- Group -->
                <fuse-vertical-navigation-group-item
                    *ngIf="item.type === 'group'"
                    [item]="item"
                    [name]="name"
                    [autoCollapse]="autoCollapse"></fuse-vertical-navigation-group-item>

                <!-- Spacer -->
                <fuse-vertical-navigation-spacer-item
                    *ngIf="item.type === 'spacer'"
                    [item]="item"
                    [name]="name"></fuse-vertical-navigation-spacer-item>

            </ng-container>

        </ng-container>

    </div>

    <!-- Footer -->
    <div class="navbar-bottom">
        <a class="social-buttons" href="https://www.facebook.com/bdsmallbands" target="_blank">
            <img src="assets/images/social/facebook.svg" alt="Facebook">
        </a>
        <a class="social-buttons" href="https://www.instagram.com/smallbandsbd/" target="_blank">
            <img src="assets/images/social/instagram.svg" alt="Instagram">
        </a>
        <a class="social-buttons" href="https://www.youtube.com/channel/UC0c79iSK3WlB_50FCycFYkQ" target="_blank">
            <img src="assets/images/social/youtube.svg" alt="Youtube">
        </a>
    </div>
</div>

<!-- Aside -->
<div
    class="fuse-vertical-navigation-aside-wrapper"
    *ngIf="activeAsideItemId"
    fuseScrollbar
    [fuseScrollbarOptions]="{wheelPropagation: false, suppressScrollX: true}"
    [@fadeInLeft]="position === 'left'"
    [@fadeInRight]="position === 'right'"
    [@fadeOutLeft]="position === 'left'"
    [@fadeOutRight]="position === 'right'">

    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

        <!-- Skip the hidden items -->
        <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

            <!-- Aside -->
            <fuse-vertical-navigation-aside-item
                *ngIf="item.type === 'aside' && item.id === activeAsideItemId"
                [item]="item"
                [name]="name"
                [autoCollapse]="autoCollapse"></fuse-vertical-navigation-aside-item>

        </ng-container>

    </ng-container>

</div>
