
<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
               <img src="assets/images/bands/bugulnoz/title.svg" alt="Bugulnoz">                                              
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/bugulnoz/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"Tournée en Russie"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/bugulnoz/avatar.png" alt="Arthur Leroi">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage du chanteur de Bugnoz</p>
                              <p class="band_testimony__content__author--name--name">Arthur Leroi</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p>Nous voilà partis en <b>Russie pour une tournée de 2 semaines.</b></p>
                <p>Un peu loin pour y aller en voiture, on décide de prendre l’avion. Du coup, compliqué de prendre tout notre matériel et on prend seulement l'essentiel : guitares, basses, baguettes, etc.  <b>Le Tour Manager nous avait garanti que toutes les salles de concert seraient parfaitement équipées</b>, donc on ne se tracasse pas trop!</p>
                <p>Troisième jour de notre tournée, on arrive dans un petit bar après des heures et des heures de route. Quelle claque pour Gilles, l’un des guitaristes, quand il a croisé le regard de ce prétendu ampli de guitare... Vous l'avez vu sur la photo ci-dessous, <b>cet ampli devait faire la taille d'une boîte à chaussures!</b></p><br/>
                <p>Fous rires pour tous les membres du groupe, enfin peut-être pas tous...</p>
              </div>
              <div class="gallery">
                 <img src="/assets/images/bands/bugulnoz/gallery_thumbnail_1.jpg" (click)="showGallery(0)">
              </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Comme une licorne (punk en kilt)</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/WSHg5eAdp40" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc">
        <iframe src="https://open.spotify.com/embed/artist/1DD0bA72i7L40njnm4rla4?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/bands/bugulnoz/kilt.png" alt="Kilt" class="top-right">
        <img src="assets/images/bands/bugulnoz/kilt2.png" alt="Kilt" class="bottom-left">
    </div>
</div>