import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';

import { GroupsComponent } from './pages/groups/groups.component';
import { ConceptComponent } from './pages/concept/concept.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { OrderComponent } from './pages/order/order.component';
import { ContactComponent } from './pages/contact/contact.component';
import { GroupBottomComponent } from './shared/components/group-bottom/group-bottom.component';
import { GroupTitleComponent } from './shared/components/group-title/group-title.component';
import { GroupBiographyComponent } from './shared/components/group-biography/group-biography.component';
import { GroupSocialComponent } from './shared/components/group-social/group-social.component';
import { GroupSkalComponent } from './pages/group-skal/group-skal.component';
import { GroupGazigannComponent } from './pages/group-gazigann/group-gazigann.component';
import { GroupBarakaComponent } from './pages/group-baraka/group-baraka.component';


import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'environments/environment';


import {IvyGalleryModule} from 'angular-gallery';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GroupGbbComponent } from './pages/group-gbb/group-gbb.component';
import { LandingComponent } from './pages/landing/landing.component';
import { GroupBugulnozComponent } from './pages/group-bugulnoz/group-bugulnoz.component';
import { GroupYvesPaquetComponent } from './pages/group-yves-paquet/group-yves-paquet.component';
import { GroupShaarghotComponent } from './pages/group-shaarghot/group-shaarghot.component';
import { GroupThomasFrankHopperComponent } from './pages/group-thomas-frank-hopper/group-thomas-frank-hopper.component';
import { GroupVersatileComponent } from './pages/group-versatile/group-versatile.component';
import { GroupKidNoizeComponent } from './pages/group-kid-noize/group-kid-noize.component';
import { GroupLaJungleComponent } from './pages/group-la-jungle/group-la-jungle.component';
import { GroupSmokebombComponent } from './pages/group-smokebomb/group-smokebomb.component';
import { GroupChumpComponent } from './pages/group-chump/group-chump.component';
import { GroupFleddyMelculyComponent } from './pages/group-fleddy-melculy/group-fleddy-melculy.component';
import { GroupRudyLennersComponent } from './pages/group-rudy-lenners/group-rudy-lenners.component';
import { GroupEvykaComponent } from './pages/group-evyka/group-evyka.component';
import { FestivalComponent } from './pages/festival/festival.component';



const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy       : PreloadAllModules,
    relativeLinkResolution   : 'legacy'
};

@NgModule({
    declarations: [
        AppComponent,
        GroupSkalComponent,
        GroupGazigannComponent,
        GroupsComponent,
        ConceptComponent,
        RegistrationComponent,
        OrderComponent,
        ContactComponent,
        GroupBottomComponent,
        GroupTitleComponent,
        GroupBiographyComponent,
        GroupSocialComponent,
        GroupBarakaComponent,
        GroupGbbComponent,
        LandingComponent,
        GroupBugulnozComponent,
        GroupYvesPaquetComponent,
        GroupShaarghotComponent,
        GroupThomasFrankHopperComponent,
        GroupVersatileComponent,
        GroupKidNoizeComponent,
        GroupLaJungleComponent,
        GroupSmokebombComponent,
        GroupChumpComponent,
        GroupFleddyMelculyComponent,
        GroupRudyLennersComponent,
        GroupEvykaComponent,
        FestivalComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse & Fuse Mock API
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),

        // Gallery
        IvyGalleryModule,

        // FontAwsome
        FontAwesomeModule,

        // Firebase stuff
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule


    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
