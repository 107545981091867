import { Component, OnInit } from '@angular/core';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-festival',
  templateUrl: './festival.component.html',
  styleUrls: ['./festival.component.scss']
})
export class FestivalComponent implements OnInit {

  constructor(private gallery: Gallery) { }

  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/festival/photos/1.jpg'},
            {path: '/assets/images/festival/photos/2.jpg'},
            {path: '/assets/images/festival/photos/3.jpg'},
            {path: '/assets/images/festival/photos/4.jpg'},
            {path: '/assets/images/festival/photos/5.jpg'},
            {path: '/assets/images/festival/photos/6.jpg'},
            {path: '/assets/images/festival/photos/7.jpg'},
            {path: '/assets/images/festival/photos/8.jpg'},
            {path: '/assets/images/festival/photos/9.jpg'},
            {path: '/assets/images/festival/photos/10.jpg'},
            {path: '/assets/images/festival/photos/11.jpg'},
            {path: '/assets/images/festival/photos/12.jpg'},
            {path: '/assets/images/festival/photos/13.jpg'},
            {path: '/assets/images/festival/photos/14.jpg'},
            {path: '/assets/images/festival/photos/15.jpg'},
            {path: '/assets/images/festival/photos/16.jpg'},
            {path: '/assets/images/festival/photos/17.jpg'},
            {path: '/assets/images/festival/photos/18.jpg'},
            {path: '/assets/images/festival/photos/19.jpg'},
            {path: '/assets/images/festival/photos/20.jpg'},
            {path: '/assets/images/festival/photos/21.jpg'},
            {path: '/assets/images/festival/photos/23.jpg'},
            {path: '/assets/images/festival/photos/24.jpg'},
            {path: '/assets/images/festival/photos/25.jpg'},
            {path: '/assets/images/festival/photos/26.jpg'},
            {path: '/assets/images/festival/photos/27.jpg'},
            {path: '/assets/images/festival/photos/28.jpg'},
            {path: '/assets/images/festival/photos/30.jpg'},
            {path: '/assets/images/festival/photos/31.jpg'},
            {path: '/assets/images/festival/photos/32.jpg'},
            {path: '/assets/images/festival/photos/33.jpg'},
            {path: '/assets/images/festival/photos/34.jpg'},
            {path: '/assets/images/festival/photos/35.jpg'},
            {path: '/assets/images/festival/photos/36.jpg'},
            {path: '/assets/images/festival/photos/37.jpg'},
            {path: '/assets/images/festival/photos/38.jpg'},
            {path: '/assets/images/festival/photos/39.jpg'},
            {path: '/assets/images/festival/photos/40.jpg'},
            {path: '/assets/images/festival/photos/41.jpg'},
            {path: '/assets/images/festival/photos/42.jpg'},
            {path: '/assets/images/festival/photos/43.jpg'},
            {path: '/assets/images/festival/photos/44.jpg'},
            {path: '/assets/images/festival/photos/45.jpg'},
            {path: '/assets/images/festival/photos/46.jpg'},
            {path: '/assets/images/festival/photos/47.jpg'},
            {path: '/assets/images/festival/photos/48.jpg'},
            {path: '/assets/images/festival/photos/49.jpg'}
        ],
        index
    };
    this.gallery.load(prop);
  }

  ngOnInit(): void {
  }

}
