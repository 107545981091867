import { Component, OnInit } from '@angular/core';
import { Band } from 'app/shared/interfaces/band';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-group-rudy-lenners',
  templateUrl: './group-rudy-lenners.component.html',
  styleUrls: ['./group-rudy-lenners.component.scss']
})
export class GroupRudyLennersComponent implements OnInit {

  band: Band;

  constructor(private gallery: Gallery) { }

  showGallery(index: number) {
    let prop = {
        images: [
            {path: '/assets/images/bands/rudylenners/gallery_zoom_1.jpg'},
            {path: '/assets/images/bands/rudylenners/gallery_zoom_2.jpg'},
            {path: '/assets/images/bands/rudylenners/gallery_zoom_3.jpg'}
        ],
        index
    };
    this.gallery.load(prop);
  }

  ngOnInit(): void {
    this.band = {
      name: "Rudy Lenners",
      genre: "Ex-batteur des Scorpions",
      biography: "<b>Rudy Lenners rejoint Scorpions en 1975</b> en remplaçant de Jürgen Rosenthal, parti effectuer son service militaire, et à l'occasion de la sortie de <b>l'album In Trance</b>, premier album du groupe à connaître un certain succès et considéré aujourd'hui comme un classique. L'année suivante, il sort avec le groupe <b>l'album Virgin Killer</b> qui, dans la lignée de In Trance, est un autre album de qualité, classique des Scorpions et du hard rock des années 1970, qui obtient un franc succès au Japon, où il est certifié disque d'or. Cet album amorce une période de grande popularité du groupe dans le monde entier. Mais il fut aussi le dernier qu'il enregistra avec Scorpions.",
      image: "assets/images/bands/rudylenners/banner.jpg",
      logo: "assets/images/bands/rudylenners/logo.png",
      title: "Rudy Lenners",
      teamImage: "/assets/images/bands/rudylenners/small-bands-rudylenners-band.png",
      facebookURL: "https://www.facebook.com/Scorpions/",
      instagramURL: "https://www.instagram.com/scorpions/",
      youtubeURL: "https://www.youtube.com/channel/UCcvnDgwSH5Dl2b3Bxfz4OCQ",
      websiteURL: "https://www.the-scorpions.com/",
    }
  }

}
