<div class="wrapper">
    <div class="wrapper__content">
       <div class="content-bloc">
          <div class="content-bloc__header">
            <svg width="185" height="91" viewBox="0 0 185 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M76.4878 0.585381V21.0732C76.4878 22.439 76.2927 23.4146 75.7073 24.3903C74.7317 26.1464 73.1707 26.9268 70.8292 26.9268C68.2927 26.9268 66.5366 26.3415 65.7561 24.9756C65.1707 24.1951 64.9756 22.6342 64.9756 20.2927V0.585381L69.4634 0.390259C69.4634 1.17075 69.4634 4.48782 69.4634 10.7317C69.4634 16.7805 69.4634 19.9025 69.4634 19.7073C69.4634 21.4634 69.8536 22.2439 70.8292 22.2439C71.6097 22.2439 72 21.4634 72 19.7073V0.585381H76.4878Z" fill="white"/>
                <path d="M90.3416 0.780518L90.1464 26.1464L85.4635 26.3415C84.683 23.0244 83.9025 19.3171 82.9269 15.0244C82.9269 14.6342 82.7318 14.4391 82.5367 14.4391C82.3416 14.4391 82.3416 14.6342 82.3416 15.2195C82.3416 15.6098 82.3416 17.1708 82.3416 19.9025C82.3416 22.6342 82.3416 24.5854 82.3416 26.1464L78.0489 26.3415C78.0489 23.2195 77.8538 18.5366 78.0489 12.2927C78.0489 6.24393 78.244 2.34149 78.244 0.780518H83.122C84.2928 6.04881 85.0733 9.561 85.4635 11.122C85.6586 11.7073 85.6586 11.9025 85.8538 11.9025C86.0489 11.9025 86.0489 11.5122 86.0489 10.9269C85.8538 9.17076 85.8538 5.65857 85.8538 0.780518H90.3416Z" fill="white"/>
                <path d="M100.098 26.9268C96.9757 27.1219 94.244 27.1219 91.7074 26.9268C91.5123 26.9268 91.3171 26.7317 91.3171 26.3415V0.390244C91.5123 0.195122 91.5123 0 91.7074 0C93.4635 0 96.1952 0 99.9025 0L100.098 4.4878C99.122 4.4878 97.9513 4.4878 96.1952 4.4878C95.8049 4.4878 95.6098 4.68293 95.6098 4.87805C95.6098 5.07317 95.6098 5.85366 95.6098 7.21951C95.6098 8.39024 95.6098 9.36585 95.6098 9.95122C95.6098 10.1463 95.8049 10.3415 96.0001 10.3415C96.3903 10.3415 97.561 10.3415 99.122 10.3415V15.6098H96.1952C96.0001 15.6098 95.8049 15.8049 95.8049 16.1951C95.6098 17.1707 95.6098 18.9268 95.8049 21.4634C95.8049 21.6585 96.0001 21.8537 96.3903 22.0488C97.1708 22.0488 98.5366 22.0488 100.098 22.0488V26.9268Z" fill="white"/>
                <path d="M18.5366 72.7805C18.5366 74.7317 18.3415 76.6829 17.7561 78.439C16.7805 81.7561 15.2195 83.5122 12.878 83.5122C12.0976 83.5122 14.0488 83.9024 18.5366 84.6829L17.561 90.5366C16.7805 90.5366 12.4878 89.561 4.87805 87.8049C3.70732 87.4146 3.90244 85.8537 5.46341 83.3171C4.09756 83.3171 2.92683 82.3415 1.7561 80.5854C0.585366 78.6341 0 75.9024 0 72.7805V44.878C0 38.2439 2.92683 35.1219 8.97561 35.1219C15.2195 35.1219 18.3415 38.0488 18.3415 44.0976L18.5366 72.7805ZM11.3171 70.439V47.6098C11.3171 45.8537 11.1219 44.4878 10.7317 43.9024C10.5366 43.5122 9.95122 43.3171 9.17073 43.3171C7.60975 43.3171 7.02439 44.6829 7.02439 47.6098V70.439C7.02439 73.561 7.80488 75.3171 9.17073 75.3171C10.7317 75.3171 11.3171 73.7561 11.3171 70.439Z" fill="white"/>
                <path d="M41.5609 36.4877L41.3657 73.3658C41.3657 75.7072 40.7804 77.6585 39.8048 79.4145C38.2438 82.3414 35.317 83.9024 31.2194 83.9024C26.5365 83.9024 23.6096 82.7316 22.2438 80.5853C21.2682 79.2194 20.8779 76.4877 20.8779 72.3902V36.4877L29.073 36.2926C29.073 37.6585 29.073 43.7072 29.073 54.8292C29.073 65.756 29.073 71.2194 29.073 70.8292C29.073 73.9511 29.8535 75.5121 31.4145 75.5121C32.7804 75.5121 33.5609 73.9511 33.5609 70.8292V36.4877H41.5609Z" fill="white"/>
                <path d="M60.0975 83.9024C54.6341 84.0976 49.5609 84.0976 45.2682 83.7073C44.878 83.7073 44.6829 83.3171 44.6829 82.5366V36.0976C44.6829 35.7073 44.878 35.5122 45.2682 35.5122C48.3902 35.5122 53.2682 35.5122 60.0975 35.5122L60.2926 43.7073C58.7316 43.7073 56.3902 43.7073 53.4633 43.7073C52.878 43.7073 52.4877 43.9024 52.4877 44.2927C52.4877 44.4878 52.4877 45.8537 52.4877 48.5854C52.4877 50.7317 52.4877 52.4878 52.4877 53.6585C52.4877 54.0488 52.6829 54.439 53.2682 54.439C54.0487 54.6342 55.9999 54.6342 58.7316 54.439V64H53.4633C53.0731 64 52.878 64.3902 52.6829 64.9756C52.4877 66.7317 52.4877 69.8537 52.4877 74.3415C52.4877 74.9268 52.878 75.1219 53.4633 75.3171C55.0243 75.3171 57.1707 75.3171 60.0975 75.122V83.9024Z" fill="white"/>
                <path d="M83.1219 72.5854C83.1219 76.0976 82.1463 78.8293 80.3902 80.7805C78.6341 82.7318 76.0975 83.9025 72.7804 83.9025C70.0487 83.9025 67.5121 82.7317 65.5609 80.5854C63.4146 78.2439 62.439 75.122 62.439 71.4147C62.439 70.8293 62.439 69.6586 62.439 68.2927H70.439V71.2196C70.439 74.1464 71.2194 75.5122 72.7804 75.5122C73.9512 75.5122 74.7316 74.5366 74.7316 72.5854L74.9268 69.2683C74.9268 68.2927 73.9512 66.7318 71.8048 64.7805C68.2926 61.2683 66.3414 59.3171 65.9512 58.9269C63.8048 56.3903 62.8292 54.2439 62.8292 52.2927V47.2196C62.8292 43.7074 63.6097 40.7805 65.3658 38.6342C67.1219 36.2927 69.8536 35.122 73.3658 34.9269C76.2926 34.9269 78.439 35.9025 80.1951 37.8537C81.756 39.8049 82.5365 42.3415 82.5365 45.4635V51.3171H74.5365C74.5365 50.3415 74.5365 48.7805 74.7316 46.6342C74.7316 45.8537 74.5365 45.0732 74.3414 44.0976C74.1463 43.3171 73.756 42.9269 73.1707 42.9269C71.6097 42.9269 70.8292 44.2927 70.8292 46.8293V49.9513C70.8292 51.122 71.8048 52.8781 73.9512 55.2196C75.9024 57.3659 77.8536 59.5122 79.8048 61.4635C81.9512 64 82.9268 66.3415 82.9268 68.4878V72.5854H83.1219Z" fill="white"/>
                <path d="M104.975 43.5122C104.78 43.5122 104.195 43.5122 103.219 43.5122C102.439 43.5122 101.658 43.5122 101.073 43.5122C100.293 43.5122 99.9023 43.9024 99.9023 44.4878L100.097 82.9268H91.9023V44.4878C91.9023 43.9024 91.7072 43.7073 91.1218 43.7073H86.634L86.8292 35.7073C91.7072 35.7073 97.9511 35.7073 105.171 35.7073L104.975 43.5122Z" fill="white"/>
                <path d="M117.268 82.1464L108.878 81.9512C108.878 68.4878 109.073 53.6586 108.878 37.2683C108.878 36.8781 111.61 36.8781 117.268 37.2683C117.268 48.7805 117.268 63.8049 117.268 82.1464Z" fill="white"/>
                <path d="M138.537 74.3414C138.537 80.9755 135.415 84.2926 128.976 84.2926C125.659 84.2926 123.317 83.5121 121.951 81.756C120.585 80.1951 120 77.6585 120 74.3414V46.8292C120 42.5365 120.78 39.4146 122.537 37.6585C123.902 36.0975 126.049 35.317 128.976 35.317C132.098 35.317 134.244 35.9024 135.805 37.2682C137.561 39.0243 138.537 41.9512 138.537 46.0487V74.3414ZM130.927 71.6097V47.6097C130.927 45.2682 130.341 44.0975 129.171 44.0975C128 44.0975 127.415 45.2682 127.415 47.6097V71.6097C127.415 72.9755 127.61 73.756 127.805 74.1463C128 74.5365 128.585 74.7316 129.171 74.7316C130.341 74.7316 130.927 73.756 130.927 71.6097Z" fill="white"/>
                <path d="M163.512 36.878L163.122 82.3414L154.732 82.7316C153.366 76.878 151.805 70.0487 150.244 62.4389C150.049 61.8536 149.854 61.4633 149.463 61.4633C149.268 61.4633 149.073 61.8536 149.073 62.6341C149.073 63.4145 149.073 66.3414 149.073 71.0243C149.073 75.7072 149.073 79.4145 149.073 82.1463L141.268 82.3414C141.073 76.878 141.073 68.4877 141.073 57.1706C141.073 46.4389 141.268 39.4146 141.463 36.4877L150.439 36.2926C152.39 45.8536 153.756 52.0975 154.537 54.8292C154.927 55.8048 155.122 56.3902 155.317 56.3902C155.512 56.3902 155.707 55.8048 155.707 54.6341C155.512 51.5121 155.512 45.2682 155.512 36.2926H163.512V36.878Z" fill="white"/>
                <path d="M184.976 42.5365L184.781 55.2195C184.781 57.7561 184 60.0975 182.634 61.8536C181.463 63.4146 180.293 64.1951 179.122 64.1951C178.732 64.1951 178.537 64.5853 178.537 65.1707C178.537 65.3658 178.537 65.9512 178.537 67.1219C178.537 68.0975 178.537 68.6829 178.537 69.2682C178.537 69.6585 176.39 69.8536 172.098 69.4634C172.098 68.6829 172.098 64.9756 172.098 58.5365C172.098 57.9512 172.293 57.5609 172.878 57.5609C173.659 57.3658 174.049 57.1707 174.439 57.1707C175.024 56.9756 175.61 56.5853 176.195 56C176.976 55.2195 177.366 51.7073 177.366 45.8536C177.366 43.7073 176.585 42.7317 175.024 42.7317C173.463 42.7317 172.488 43.7073 172.488 45.8536L172.293 49.3658C167.61 49.5609 165.268 49.7561 165.268 49.3658C165.268 48.9756 165.268 47.4146 165.463 44.4878C165.463 41.1707 166.439 38.8292 168 37.4634C169.561 36.2926 172.098 35.7073 175.61 35.7073C178.146 35.7073 180.488 36.2926 182.244 37.6585C184.195 38.6341 185.171 40.3902 184.976 42.5365ZM179.122 83.317C174.244 83.7073 171.707 83.5122 171.707 82.7317C171.707 80.9756 171.707 78.6341 171.707 76.0975C171.707 75.7073 174.049 75.7073 179.122 76.2926C179.122 77.6585 179.122 80 179.122 83.317Z" fill="#46B8AC"/>
            </svg>
          </div>
          <div class="content-bloc__content">
            <h1>Contact</h1>
            <p>N'hésite pas à me contacter via le formulaire ci-dessous si tu as des questions, remarques, avis ou autre sur le projet. Si tu veux t'inscrire, toi ou ton groupe pour peut-être avoir l'occasion d'avoir une ou plusieurs pages de BD dans Small Bands, je t'invite à remplir le formulaire dans <a [routerLink]="['/registration']">"Inscrire son groupe"</a>.</p>
            <div class="qualifio_campaign" style="position: relative;">
                <div id="qualifio_insert_place_869298" class="qualifio_iframe_wrapper"></div>
                <!-- Material Design Spinner -->
                <div class="spinner-wrapper">
                    <div class="spinner">
                        <div class="inner">
                            <div class="gap"></div>
                            <div class="left">
                                <div class="half-circle"></div>
                            </div>
                            <div class="right">
                                <div class="half-circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Material Design Spinner -->
            </div>
          </div>
       </div>
    </div>
    <!-- <div class="wrapper__background">
        <img src="assets/images/global/envelope.png" alt="Enveloppe" class="top-right">
        <img src="assets/images/global/timbre1.png" alt="Timbre" class="bottom-right">
        <img src="assets/images/global/timbre2.png" alt="Timbre" class="top-left">
        <img src="assets/images/global/timbre3.png" alt="Timbre" class="bottom-left">
    </div> -->
</div>