<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <img src="assets/images/bands/smokebomb/title.svg" alt="SmokeBomb">                                         
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/smokebomb/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"After Party!"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/smokebomb/avatar.png" alt="Tan">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage du bassiste de SMKBMB</p>
                              <p class="band_testimony__content__author--name--name">Tan (aka Mr TanBomb)</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p><b>Première édition du Couvy Rock en 2022 !</b> Nous étions tout heureux d'avoir un tel gîte, c'est la première fois qu'on avait de telles conditions. Du coup, on a voulu en faire profiter Chump avec qui on passait une sacrée soirée.</p>
                <p>Pour finalement le résultat illustré dans ces cases 😅😂</p>
                <p>En plus, comme Niko, chanteur et guitariste de <a [routerLink]="['/chump']">Chump</a>, l'a mentionné, <b>le gîte qu'on a tellement peiné à trouver était en fait juste derrière le site du festival...</b></p>                    
                <p>La leçon à tirer c'est de toujours aller vérifier l'endroit où tu vas dormir à l'avance...</p>
                <p>On est en tout cas content de pouvoir partager nos cases avec Chump, une manière de se rattraper.</p>
                <img src="assets/images/bands/smokebomb/poster.png" alt="Poster">
              </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
        <h1>Harvest</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/vDiDZmHa3dU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc bloc-smokebomb">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/4XvSoPHKsDlan93Ct05xk2?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
       <img src="assets/images/bands/smokebomb/deco1.png" alt="Album" class="top-right">
       <img src="assets/images/bands/smokebomb/deco2.png" alt="Album" class="bottom-left">
    </div>
</div>