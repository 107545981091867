
<div class="wrapper">
    <div class="wrapper__content">
       <div class="content-bloc">
          <div class="content-bloc__header">
            <svg width="116" height="77" viewBox="0 0 116 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.0682 64.0198C23.0682 67.9798 21.9697 71.0598 19.9924 73.2598C18.0152 75.4598 15.1591 76.7798 11.4242 76.7798C8.34848 76.7798 5.49242 75.4598 3.29545 73.0398C1.09848 70.6198 0 67.0998 0 62.9198C0 62.2598 0 60.9398 0 59.3998H9.00758V62.6998C9.00758 65.9998 9.88636 67.5398 11.6439 67.5398C12.9621 67.5398 13.8409 66.4398 13.8409 64.2398L14.0606 60.4998C14.0606 59.3998 12.9621 57.6398 10.5455 55.4398C6.59091 51.4798 4.39394 49.2798 3.95455 48.8398C1.53788 45.9798 0.439394 43.5598 0.439394 41.3598V35.6398C0.439394 31.6798 1.31818 28.3798 3.29545 25.9598C5.27273 23.3198 8.34848 21.9998 12.303 21.7798C15.5985 21.7798 18.0152 22.8798 19.9924 25.0798C21.75 27.2798 22.6288 30.1398 22.6288 33.6598V40.2598H13.6212C13.6212 39.1598 13.6212 37.3998 13.8409 34.9798C13.8409 34.0998 13.6212 33.2198 13.4015 32.1198C12.9621 31.2398 12.5227 30.7998 11.8636 30.7998C10.1061 30.7998 9.22727 32.3398 9.22727 35.1998V38.7198C9.22727 40.0398 10.3258 42.0198 12.7424 44.6598C14.9394 47.0798 17.1364 49.4998 19.3333 51.6998C21.75 54.5598 22.8485 57.1998 22.8485 59.6198V64.0198H23.0682Z" fill="white"/>
                <path d="M48.553 75.4599L38.2273 75.6799C37.5682 73.6999 36.4697 68.8599 34.4924 61.1599C34.2727 60.4999 34.2727 60.0599 34.053 60.0599C34.053 60.0599 34.053 60.4999 33.8333 61.1599C33.6136 64.4599 33.6136 69.2999 33.8333 75.6799L25.7046 75.4599V23.3199H34.2727C34.053 31.4599 34.053 36.0799 34.053 37.6199C34.053 37.8399 34.053 38.0599 34.2727 38.0599C34.4924 38.0599 34.4924 37.8399 34.7121 37.3999C35.3712 34.5399 36.6894 29.9199 38.2273 23.3199L47.894 23.0999C47.894 23.3199 47.894 23.3199 47.894 23.5399C47.0152 25.5199 45.2576 29.4799 42.6212 35.8599C39.9849 42.2399 38.6667 45.7599 38.6667 45.9799C40.8637 53.6799 44.3788 63.5799 48.553 75.4599Z" fill="white"/>
                <path d="M78.4318 76.9997H68.9849L68.3258 72.5997C67.8864 70.3997 67.447 68.6397 67.2273 66.8797C67.2273 66.6597 67.0076 66.4397 66.5682 66.4397H63.9318C63.7121 66.4397 63.4924 66.6597 63.2728 66.8797C62.8334 69.5197 62.394 72.5997 61.7349 76.1197L52.0682 75.8997C53.6061 66.8797 55.144 57.6397 56.6818 48.3997L61.9546 21.9997C64.3712 21.7797 66.7879 21.7797 69.2046 21.9997L74.2576 48.6197C75.5758 58.0797 76.894 67.5397 78.4318 76.9997ZM66.7879 58.0797C66.1288 53.0197 65.6894 50.3797 65.4697 50.3797C65.25 50.3797 65.0303 51.4797 64.5909 53.6797C64.3712 55.2197 64.1515 56.5397 64.1515 58.0797C64.1515 58.2997 64.3712 58.2997 64.8106 58.2997C65.25 58.2997 65.6894 58.2997 66.1288 58.2997C66.5682 58.2997 66.7879 58.2997 66.7879 58.0797Z" fill="white"/>
                <path d="M98.8636 76.3398C99.0833 76.3398 96.2272 76.3398 90.0757 76.5598C86.3409 76.5598 83.7045 76.5598 82.1666 76.3398C81.5075 76.3398 81.0682 75.6798 81.0682 74.7998C80.8485 52.1398 80.8484 34.9798 80.8484 23.5398C87 23.3198 90.2954 23.3198 90.2954 23.7598C90.5151 25.7398 90.5151 31.6798 90.2954 42.0198C90.2954 53.0198 90.2954 60.7198 90.2954 64.8998C90.2954 65.9998 90.9545 66.4398 91.8333 66.4398H98.8636V76.3398Z" fill="white"/>
                <path d="M116 45.0998C116 46.4198 116 47.0798 116 47.5198C115.561 50.5998 114.682 55.2198 112.924 60.9398C112.485 60.9398 111.606 60.9398 110.508 61.1598C109.629 61.1598 108.97 61.1598 108.53 61.1598L105.894 47.5198L105.674 45.3198C105.674 43.7798 105.674 40.6998 105.674 36.2998C105.674 30.1398 105.674 25.9598 105.674 23.7598L115.78 23.3198C115.78 26.6198 115.78 30.7998 116 36.0798C116 41.1398 116 44.2198 116 45.0998ZM116 76.3398H105.894C105.674 73.0398 105.674 70.1798 105.674 67.5398H116C116 69.2998 116 72.1598 116 76.3398Z" fill="#46B8AC"/>
                <path d="M72.0606 14.08C72.0606 16.5 69.6439 17.6 64.5909 17.6C61.9545 17.6 60.197 17.38 59.0985 16.72C58 16.06 57.5606 15.18 57.5606 14.08V4.18C57.5606 2.64 58.2197 1.54 59.5379 0.88C60.6364 0.22 62.3939 0 64.5909 0C67.0076 0 68.7652 0.22 69.8636 0.66C71.1818 1.32 72.0606 2.42 72.0606 3.74V14.08ZM66.1288 13.2V4.4C66.1288 3.52 65.6894 3.08 64.8106 3.08C63.9318 3.08 63.2727 3.52 63.2727 4.4V13.2C63.2727 13.64 63.2727 14.08 63.4924 14.08C63.7121 14.08 64.1515 14.3 64.5909 14.3C65.6894 14.3 66.1288 13.86 66.1288 13.2Z" fill="white"/>
            </svg>                                                     
          </div>
          <div class="content-bloc__content">
            <!-- Create component -->
            <div class="band_testimony">
                <div class="band_testimony__comics">
                    <img src="assets/images/bands/skal/bd.png" alt="Small Bands BD">
                </div>
                <div class="band_testimony__content">
                    <h3>Tome 1</h3>
                    <h2>"Ça tombe mal"</h2>
                    <div class="band_testimony__content__author">
                        <div class="band_testimony__content__author--avatar">
                            <img src="assets/images/bands/skal/avatar.png" alt="Martin Hauchart">
                        </div>
                        <div class="band_testimony__content__author--name">
                            <p class="band_testimony__content__author--name--title">Témoignage du batteur de Skål</p>
                            <p class="band_testimony__content__author--name--name">Martin Hauchart</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Create component -->
            <div class="story">
                <p>La planche <b>“Ça tombe mal”</b> représente une des situations les plus frustrantes qu’on ait eu avec Skål! On avait attendu avec impatience cette date du 7 octobre 2017 pour jouer sur la grande scène d’un festival à Liège accueillant plus de 10.000 personnes.</p>
                <p>De <b>22:00 à 23:00</b>, le public était au rendez-vous et le groupe qui jouait juste avant nous avait enflammé la scène!</p>
                <p>De notre côté la pression se faisait sentir...</p>
                <p>Arrivés à <b>23:00</b> sur scène on avait 30 min pour s’installer et faire notre checksound. Malheureusement, pendant ces 30 minutes l'ambiance a radicalement changé: un orage violent a éclaté et a fait fuir la foule en panique.</p>
                <p>Résultat, à <b>23:30</b>, il ne restait plus personne... seuls quelques vaillants festivaliers avaient bravé l’orage pour nous voir.</p>
            </div>
            <div class="gallery">
                <img src="/assets/images/bands/skal/gallery_thumbnail_1.jpg" (click)="showGallery(0)">
                <img src="/assets/images/bands/skal/gallery_thumbnail_2.jpg" (click)="showGallery(1)">
            </div>
          </div>
       </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
            <h1>Speed Drawing</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/WeQZMDx2Drs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
       </div>
       <div class="content-bloc content-bloc--speeddrawing">
            <h1>Skål - The Teaser</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/DH29wANlU4Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
       <div class="content-bloc">
        <iframe src="https://open.spotify.com/embed/album/0kYZPCrLg3cCKk01A8I28z" width="100%" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/bands/skal/beer.png" alt="Beer" class="top-right">
        <img src="assets/images/bands/skal/frame.png" alt="Skal" class="bottom-right">
        <img src="assets/images/bands/skal/axe.png" alt="Hâche" class="top-left">
    </div>
</div>