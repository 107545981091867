<div class="wrapper">
    <div class="wrapper__content">
       <div class="content-bloc">
          <div class="content-bloc__header">
            <svg width="189" height="97" viewBox="0 0 189 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.2981 32.4909C33.2981 36.3253 31.4818 38.3433 27.8493 38.3433C26.033 38.3433 24.6204 37.9397 23.8131 36.9307C23.0059 36.1235 22.6023 34.509 22.6023 32.6927V16.75C22.6023 14.3283 23.0059 12.512 24.0149 11.503C25.024 10.4939 26.2348 10.0903 27.8493 10.0903C29.6655 10.0903 30.8764 10.4939 31.6836 11.3012C32.6927 12.3102 33.2981 13.9247 33.2981 16.3464V32.4909ZM29.0601 31.0783V17.1536C29.0601 15.7409 28.6565 15.1355 28.0511 15.1355C27.2439 15.1355 27.0421 15.7409 27.0421 17.1536V31.0783C27.0421 31.8855 27.0421 32.2891 27.2439 32.4909C27.4457 32.6927 27.6475 32.6927 28.0511 32.6927C28.6565 32.6927 29.0601 32.2891 29.0601 31.0783Z" fill="white"/>
                <path d="M47.2229 10.6958V31.8856C47.2229 33.2982 47.0211 34.3073 46.4157 35.3163C45.4066 37.1326 43.7922 37.9398 41.3705 37.9398C38.747 37.9398 36.9307 37.3344 36.1235 35.9217C35.5181 35.1145 35.3163 33.5 35.3163 31.0783V10.6958L39.9578 10.494C39.9578 11.3012 39.9578 14.732 39.9578 21.1898C39.9578 27.4458 39.9578 30.6747 39.9578 30.4729C39.9578 32.2892 40.3615 33.0964 41.3705 33.0964C42.1777 33.0964 42.5813 32.2892 42.5813 30.4729V10.6958H47.2229Z" fill="white"/>
                <path d="M66.1927 14.7319C65.9909 14.7319 65.7891 14.7319 65.1837 14.7319C64.7801 14.7319 64.1747 14.7319 63.9729 14.7319C63.5692 14.7319 63.1656 14.9337 63.1656 15.3373V37.5361H58.5241V15.3373C58.5241 14.9337 58.3222 14.9337 58.1204 14.9337H55.4969V10.2921C58.3222 10.2921 61.9548 10.2921 66.1927 10.2921V14.7319Z" fill="white"/>
                <path d="M80.1175 37.1325H75.4759L73.4579 26.8403C73.2561 26.8403 73.2561 26.8403 73.0543 26.8403C72.8525 26.8403 72.8524 27.2439 72.8524 27.6476C72.8524 31.2801 72.8524 34.3072 72.8524 37.1325H68.6145V10.0903H74.4669C75.8796 10.0903 77.0904 10.2921 78.0994 10.8976C79.1085 11.503 79.7139 12.512 79.7139 13.5211V20.1807C79.7139 21.3915 79.3103 22.1988 78.7049 23.006C78.3012 23.6114 77.6958 24.015 77.0904 24.2168C76.8886 24.2168 76.8886 24.4186 76.8886 24.4186C76.6868 24.6204 77.6958 28.8584 80.1175 37.1325ZM75.2741 16.3464C75.2741 14.9337 74.8705 14.1265 73.8615 14.1265C73.6597 14.1265 73.4579 14.1265 73.2561 14.1265C73.0543 14.1265 72.8524 14.3283 72.8524 14.7319V20.7861C72.8524 21.3915 73.0543 21.7951 73.2561 21.7951C73.4579 21.7951 73.4579 21.7951 73.6597 21.7951C74.0633 21.7951 74.4669 21.5933 74.6687 20.9879C75.2741 20.3825 75.2741 18.768 75.2741 16.3464Z" fill="white"/>
                <path d="M92.2259 32.4909C92.2259 36.3253 90.4097 38.3433 86.7771 38.3433C84.9609 38.3433 83.5482 37.9397 82.741 36.9307C81.9338 36.1235 81.5302 34.509 81.5302 32.6927V16.75C81.5302 14.3283 81.9338 12.512 82.9428 11.503C83.9518 10.4939 85.1627 10.0903 86.7771 10.0903C88.5934 10.0903 89.8042 10.4939 90.6115 11.3012C91.6205 12.3102 92.2259 13.9247 92.2259 16.3464V32.4909V32.4909ZM87.988 31.0783V17.1536C87.988 15.7409 87.5844 15.1355 86.9789 15.1355C86.1717 15.1355 85.9699 15.7409 85.9699 17.1536V31.0783C85.9699 31.8855 85.9699 32.2891 86.1717 32.4909C86.3735 32.6927 86.5753 32.6927 86.9789 32.6927C87.5844 32.6927 87.988 32.2891 87.988 31.0783Z" fill="white"/>
                <path d="M106.151 10.6958V31.8856C106.151 33.2982 105.949 34.3073 105.343 35.3163C104.334 37.1326 102.72 37.9398 100.298 37.9398C97.6746 37.9398 95.8583 37.3344 95.0511 35.9217C94.4457 35.1145 94.2439 33.5 94.2439 31.0783V10.6958L98.8855 10.494C98.8855 11.3012 98.8855 14.732 98.8855 21.1898C98.8855 27.4458 98.8855 30.6747 98.8855 30.4729C98.8855 32.2892 99.2891 33.0964 100.298 33.0964C101.105 33.0964 101.509 32.2892 101.509 30.4729V10.6958H106.151Z" fill="white"/>
                <path d="M121.488 10.8975C120.681 14.7319 120.075 19.3734 119.268 24.2168C118.461 30.0692 117.855 34.509 117.25 37.5361H111.801C111.397 34.7108 110.792 30.0692 109.985 24.015C109.178 18.3644 108.572 13.9247 108.169 10.6957L113.214 10.8975C113.214 11.3012 113.416 12.7138 113.617 15.1355C113.819 17.5572 114.223 19.7771 114.425 21.1897C114.425 21.5933 114.626 21.7951 114.626 21.7951C114.626 21.7951 114.828 21.5933 114.828 21.1897C115.03 19.7771 115.232 17.759 115.635 15.3373C115.837 13.1174 116.039 11.503 116.241 10.8975H121.488Z" fill="white"/>
                <path d="M131.578 38.1415C128.349 38.3433 125.524 38.3433 122.9 38.1415C122.699 38.1415 122.497 37.9397 122.497 37.5361V10.6957C122.497 10.4939 122.699 10.2921 122.9 10.2921C124.717 10.2921 127.542 10.2921 131.376 10.2921L131.578 14.9337C130.569 14.9337 129.358 14.9337 127.542 14.9337C127.138 14.9337 126.937 15.1355 126.937 15.3373C126.937 15.5391 126.937 16.3463 126.937 17.759C126.937 18.9698 126.937 19.9789 126.937 20.5843C126.937 20.7861 127.138 20.9879 127.34 20.9879C127.744 20.9879 128.955 20.9879 130.569 20.9879V26.4367H127.542C127.34 26.4367 127.138 26.6385 127.138 27.0421C126.937 28.0511 126.937 29.8674 127.138 32.4909C127.138 32.6927 127.34 32.8945 127.744 33.0963C128.551 33.0963 129.964 33.0963 131.578 33.0963V38.1415Z" fill="white"/>
                <path d="M144.696 37.1325H140.054L138.036 26.8403C137.834 26.8403 137.834 26.8403 137.633 26.8403C137.431 26.8403 137.431 27.2439 137.431 27.6476C137.431 31.2801 137.431 34.3072 137.431 37.1325H133.193V10.0903H139.045C140.458 10.0903 141.669 10.2921 142.678 10.8976C143.687 11.503 144.292 12.512 144.292 13.5211V20.1807C144.292 21.3915 143.889 22.1988 143.283 23.006C142.879 23.6114 142.274 24.015 141.669 24.2168C141.467 24.2168 141.467 24.4186 141.467 24.4186C141.467 24.6204 142.476 28.8584 144.696 37.1325ZM140.054 16.3464C140.054 14.9337 139.651 14.1265 138.642 14.1265C138.44 14.1265 138.238 14.1265 138.036 14.1265C137.834 14.1265 137.633 14.3283 137.633 14.7319V20.7861C137.633 21.3915 137.834 21.7951 138.036 21.7951C138.238 21.7951 138.238 21.7951 138.44 21.7951C138.843 21.7951 139.247 21.5933 139.449 20.9879C139.852 20.3825 140.054 18.768 140.054 16.3464Z" fill="white"/>
                <path d="M20.1807 83.5482C20.1807 87.3825 19.3735 90.4096 17.759 92.8313C15.9428 95.6566 13.3193 96.8675 9.88855 96.8675C5.85241 96.8675 3.02711 95.0512 1.61446 91.6205C0.605422 89.1988 0 85.5663 0 80.7229V57.3132C0 55.0934 0.605421 52.8735 1.81626 50.8554C3.63253 47.6265 6.25602 45.8102 10.0904 45.8102C13.9247 45.8102 16.75 47.2229 18.3645 50.25C19.7771 52.4699 20.3825 56.9096 20.3825 63.3675H12.3102C12.3102 61.1476 12.3102 59.3313 12.3102 57.9187C12.1084 55.6988 11.0994 54.8916 9.48494 54.8916C8.07229 54.6898 7.46687 58.3223 7.46687 65.5873V83.1446C7.46687 84.3554 7.66867 85.3644 7.87048 86.3735C8.27409 87.7861 9.08132 88.5934 9.88855 88.5934C11.503 88.5934 12.3102 86.3735 12.3102 82.1355C12.3102 81.7319 12.3102 80.3193 12.3102 78.0994C15.3373 78.0994 17.9608 78.0994 20.1807 78.0994C20.1807 79.7138 20.1807 81.5301 20.1807 83.5482Z" fill="white"/>
                <path d="M38.9489 96.8675C33.2983 97.0693 28.0513 97.0693 23.6115 96.6656C23.2079 96.6656 23.0061 96.262 23.0061 95.4548V47.4247C23.0061 47.0211 23.2079 46.8193 23.6115 46.8193C26.8404 46.8193 31.8856 46.8193 38.9489 46.8193L39.1507 55.2952C37.5362 55.2952 35.1145 55.2952 32.0874 55.2952C31.482 55.2952 31.0784 55.497 31.0784 55.9006C31.0784 56.1024 31.0784 57.5151 31.0784 60.3404C31.0784 62.5602 31.0784 64.3765 31.0784 65.5873C31.0784 65.991 31.2802 66.3946 31.8856 66.3946C32.6928 66.5964 34.7109 66.5964 37.5362 66.3946V76.2831H32.0874C31.6838 76.2831 31.482 76.6867 31.2802 77.2922C31.0784 79.1084 31.0784 82.3373 31.0784 86.9789C31.0784 87.5843 31.482 87.7861 32.0874 87.9879C33.7019 87.9879 35.9218 87.9879 38.9489 87.7861V96.8675Z" fill="white"/>
                <path d="M61.9549 55.0933C61.7531 55.0933 61.1476 55.0933 60.1386 55.0933C59.3314 55.0933 58.5241 55.0933 57.9187 55.0933C57.1115 55.0933 56.7079 55.497 56.7079 56.1024L56.9097 95.8584H48.4338V56.1024C48.4338 55.497 48.232 55.2952 47.6266 55.2952H42.985L43.1868 47.0211C48.232 47.0211 54.6898 47.0211 62.1567 47.0211L61.9549 55.0933Z" fill="white"/>
                <path d="M85.9698 55.0934C85.768 55.0934 85.1626 55.0934 84.1535 55.0934C83.3463 55.0934 82.5391 55.0934 81.9336 55.0934C81.1264 55.0934 80.7228 55.497 80.7228 56.1024V95.6566H72.2469V55.9006C72.2469 55.2952 72.0451 55.0934 71.4397 55.0934H66.7981L66.9999 46.8193C72.0451 46.8193 78.5029 46.8193 85.9698 46.8193V55.0934Z" fill="white"/>
                <path d="M106.352 96.8675C100.702 97.0693 95.4548 97.0693 91.0151 96.6656C90.6115 96.6656 90.4097 96.262 90.4097 95.4548V47.4247C90.4097 47.0211 90.6115 46.8193 91.0151 46.8193C94.244 46.8193 99.2892 46.8193 106.352 46.8193L106.554 55.2952C104.94 55.2952 102.518 55.2952 99.491 55.2952C98.8856 55.2952 98.482 55.497 98.482 55.9006C98.482 56.1024 98.482 57.5151 98.482 60.3404C98.482 62.5602 98.482 64.3765 98.482 65.5873C98.482 65.991 98.6838 66.3946 99.2892 66.3946C100.096 66.5964 102.114 66.5964 104.94 66.3946V76.2831H99.491C99.0874 76.2831 98.8856 76.6867 98.6838 77.2922C98.482 79.1084 98.482 82.3373 98.482 86.9789C98.482 87.5843 98.8856 87.7861 99.491 87.9879C101.105 87.9879 103.325 87.9879 106.352 87.7861V96.8675Z" fill="white"/>
                <path d="M142.476 89.8042C142.476 92.6295 141.063 94.8494 138.238 96.0602L120.681 95.8584V46.2138L132.587 46.012C135.009 46.012 137.229 47.0211 139.045 48.8373C140.862 50.6536 141.669 52.8735 141.669 55.2952V61.753C141.669 64.1747 141.467 65.9909 140.862 67C140.256 68.4126 139.045 69.8253 137.027 71.0361C138.44 71.6415 139.651 72.8524 140.66 74.265C141.871 75.8795 142.476 77.4939 142.476 79.3102V89.8042ZM133.395 58.3223C133.395 56.9096 133.395 55.9006 132.991 55.2952C132.587 54.4879 131.78 54.0843 130.569 54.0843C129.964 54.0843 129.358 54.0843 129.157 54.0843C128.955 54.0843 128.753 54.2861 128.753 54.6897C128.753 56.7078 128.753 60.5421 128.753 65.9909C128.753 66.5964 128.955 66.7982 129.157 66.7982C129.964 66.7982 130.771 66.5964 131.78 66.1927C132.789 65.7891 133.193 64.9819 133.193 63.9729L133.395 58.3223ZM133.596 83.3463V79.7138C133.596 77.2921 132.184 76.2831 129.358 76.2831C129.157 76.2831 128.955 76.4849 128.955 76.8885V87.9879C128.955 88.5933 129.157 88.7951 129.56 88.7951H130.368C132.587 88.7951 133.798 86.9789 133.596 83.3463Z" fill="white"/>
                <path d="M165.684 79.7138C165.684 91.2168 162.051 96.6656 154.584 96.262H145.705L145.503 47.6265V46.8192C147.319 46.6174 150.75 46.6174 155.391 46.6174C159.024 46.6174 161.648 48.4337 163.464 52.0662C164.876 54.8915 165.482 58.5241 165.482 63.1656V79.7138H165.684ZM157.813 77.2921V61.9548C157.813 60.1385 157.41 58.5241 156.401 57.1114C155.391 55.6988 154.382 54.8915 153.373 54.8915C152.97 54.8915 152.97 55.0933 152.97 55.6988C152.97 55.9006 152.97 59.9367 152.97 68.009C152.97 76.0813 152.97 82.5391 152.97 87.3825C152.97 87.9879 153.172 88.3915 153.373 88.3915C154.382 88.3915 155.391 87.3825 156.199 84.9608C157.41 82.7409 157.813 80.1174 157.813 77.2921Z" fill="white"/>
                <path d="M188.286 54.0843L188.084 67.2018C188.084 69.8253 187.277 72.247 185.864 74.0632C184.653 75.6777 183.443 76.4849 182.232 76.4849C181.828 76.4849 181.626 76.8885 181.626 77.4939C181.626 77.6958 181.626 78.3012 181.626 79.512C181.626 80.5211 181.626 81.1265 181.626 81.7319C181.626 82.3373 179.406 82.3373 174.967 81.9337C174.967 81.1265 174.967 77.2921 174.967 70.6325C174.967 70.0271 175.169 69.6235 175.774 69.6235C176.581 69.4217 176.985 69.2198 177.388 69.2198C177.994 69.018 178.599 68.6144 179.205 68.009C180.012 67.2018 180.415 63.5692 180.415 57.515C180.415 55.2952 179.608 54.2861 177.994 54.2861C176.379 54.2861 175.37 55.2952 175.37 57.515L175.169 61.1476C170.325 61.3494 167.903 61.5512 167.903 61.1476C167.903 60.7439 167.903 59.1295 168.105 56.1024C168.105 52.6717 169.114 50.25 170.729 48.8373C172.343 47.6265 174.967 47.0211 178.599 47.0211C181.223 47.0211 183.644 47.6265 185.461 49.0391C187.277 50.0482 188.286 51.8644 188.286 54.0843ZM182.03 96.4638C176.985 96.8674 174.361 96.6656 174.361 95.8584C174.361 94.0421 174.361 91.6204 174.361 88.9969C174.361 88.5933 176.783 88.5933 182.03 89.1988C182.03 90.4096 182.03 92.8313 182.03 96.4638Z" fill="#46B8AC"/>
                <path d="M42.7832 3.22891C42.7832 3.22891 43.5904 4.23795 45.0031 6.05421L42.985 8.4759C41.5724 7.66867 40.5633 7.06325 39.9579 6.45783L35.9218 3.02711C35.9218 3.02711 36.729 2.01807 38.7471 0C41.1687 2.01807 42.3796 3.02711 42.7832 3.22891Z" fill="white"/>
            </svg>                                 
          </div>
          <div class="content-bloc__content">
            <h1>Quelques points de vente</h1>
            <p>Les librairies affichées sur cette carte devraient avoir en stock la bande dessinée "Small Bands". N'hésitez pas à les contacter pour vous assurer qu'elles l'ont bien en magasin ou si vous pouvez la commander.</p>
            <iframe style="margin-top: 25px; box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); overflow: hidden; border-radius: 15px;" src="https://www.google.com/maps/d/embed?mid=1YWVqZ255ZDs69rEhZxEJZzKVzDF_9CQ&ehbc=2E312F" width="100%" height="600"></iframe>
            <h1>Points de vente en ligne</h1>
            <a class="btn_achat" target="_blank" href="https://chronica-shop.be/collections/small-bands/products/small-bands-nicolas-de-wolf-tome-1"><img src="assets/images/global/small-bands-chronica.jpg" alt="Small Bands chronica"></a>
            <a class="btn_achat" target="_blank" href="https://www.club.be/p/small-bands-t1-9782931115428"><img src="assets/images/global/small-bands-club.jpg" alt="Small Bands Club"></a>
            <a class="btn_achat" target="_blank" href="https://www.lapagedapres.be/product/show/9782931115428/small-bands-t1"><img src="assets/images/global/small-bands-librairie-la-page-d-apres.jpg" alt="Small Bands La Page d'Après"></a>
            <a class="btn_achat" target="_blank" href="https://www.moliere.com/fr/de-wolf-nicolas-small-bands-tome-1-9782931115428.html"><img src="assets/images/global/small-bands-librairie-moliere.jpg" alt="Small Bands Librairie Molière"></a>
            <a class="btn_achat" target="_blank" href="https://www.filigranes.be/small-bands-t1"><img src="assets/images/global/small-bands-librairie-filigranes.jpg" alt="Small Bands Librairie Filigranes"></a>
          </div>
       </div>
    </div>
    <div class="wrapper__background">
        <img src="assets/images/global/coffee.png" alt="Coffee" class="top-right">
    </div>
</div>