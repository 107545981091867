<div class="wrapper">
    <div class="wrapper__content">
        <div class="content-bloc">
            <div class="content-bloc__header">
                <img src="assets/images/bands/evyka/title.svg" alt="Evyka">                                              
            </div>
            <div class="content-bloc__content">
              <!-- Create component -->
              <div class="band_testimony">
                  <div class="band_testimony__comics">
                      <img src="assets/images/bands/evyka/bd.png" alt="Small Bands BD">
                  </div>
                  <div class="band_testimony__content">
                      <h3>Tome 1</h3>
                      <h2>"Les joies du grand air"</h2>
                      <div class="band_testimony__content__author">
                          <div class="band_testimony__content__author--avatar">
                              <img src="assets/images/bands/evyka/avatar.png" alt="Candice Delhez">
                          </div>
                          <div class="band_testimony__content__author--name">
                              <p class="band_testimony__content__author--name--title">Témoignage de la violoniste d'Evyka</p>
                              <p class="band_testimony__content__author--name--name">Candice Delhez</p>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /Create component -->
              <div class="story">
                <p>En rentrant chez nous, on s'est retrouvées avec une tonne de plans caméra foireux, c'était vraiment le bazar. <b>On a été obligées d'organiser une deuxième expédition pour rectifier le désastre.</b></p>
                <p>Et rebelote, on a dû ramener tout notre attirail : maquillage, costumes, drone pour les plans de paysage, et tout le reste, pour un deuxième round. En plus, il a fallu trouver un autre jour avec le même type de météo pour que nos nouveaux plans fonctionnent avec les premiers. <b>Et en Belgique, c’est pas toujours gagné...</b></p>
                <br/>
                <p><b>Voici le clip qu'on a finalement réussi à réaliser :</b></p>
                <div class="embedresize">
                    <div>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/-QGOSkokkwo?si=jC90qIqG9PEjRNnv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
              </div>
            </div>
         </div>
       <div class="content-bloc">
            <app-group-bottom [band] = "band"></app-group-bottom>
       </div>
       <div class="content-bloc content-bloc--speeddrawing content-bloc content-bloc--speeddrawing__twocol">
        <div class="speedcol">
            <h1>Snowdrop</h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/SeQpSwY7KxE?si=BCHrXOTGo9D3GOkh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="speedcol">
            <h1>Underground <span style="font-size: 17px;">(Lindsey Stirling cover)</span></h1>
            <div class="embedresize">
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ivpdujvphkk?si=Nddb0YDzBkhFze2D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
       </div>
       <div class="content-bloc">
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/60qzSQ3RDO1ztjTCdOSTrU?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
       <img src="assets/images/bands/evyka/deco1.png" alt="Album" class="top-right">
       <img src="assets/images/bands/evyka/deco2.png" alt="Album" class="bottom-left">
    </div>
</div>
